import {AuthRoutingModule} from './auth-routing.module';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [
  ],
  imports: [
    FormsModule,
    AuthRoutingModule,
    SharedModule,
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ]
})
export class AuthModule {}
