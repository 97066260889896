import { MenuItem } from 'primeng/api';
export interface JobMapping {
  pageObject: {};
  jobMappingMappings;
  jobMappingJobs;
  jobMappingDataColumns;
  filteredJobMapping: [];
  cleanJobMapping: [];
  changedData;
  isLoading: boolean;
}
export class JobMappingState implements JobMapping {
  pageObject: {} =
    localStorage.getItem('page') != null
      ? JSON.parse(localStorage.getItem('page'))
      : {};
  jobMappingMappings: {};
  jobMappingJobs: {};

  jobMappingDataColumns = [
    { field: 'PosJobTitle', translationLabel: 'POS Job' },
    { field: 'PayrollJob', translationLabel: 'Payroll Job' }
  ];

  filteredJobMapping: [];
  cleanJobMapping: [];
  changedData = [];
  isLoading = false;
}
