export interface Transactions {
  pageObject: {};
  employeesFromApi;
  dataFromApi;
  columns: {}[];
  rowData;
  isDataEmpty: boolean;
  isDataFetching: boolean;
  isInnerLoading: boolean;
  fetchingError: boolean;
  isEditButtonDisabled: boolean;
  showTransactionsEditPopUp:boolean;
}
export class TransactionsState implements Transactions {
  pageObject: {} =
    localStorage.getItem('page') != null
      ? JSON.parse(localStorage.getItem('page'))
      : {};
  dataFromApi = [];
  employeesFromApi = [];
  rowData = {};
  isDataEmpty = true;
  isDataFetching = false;
  isInnerLoading = false;
  fetchingError = false;
  isEditButtonDisabled = false;
  showTransactionsEditPopUp = false;
  columns = [
    { field: 'repTrans_CustomerName', translationLabel: 'Customer Name' },
    { field: 'repTrans_BusinessDate', translationLabel: 'Business date' },
    { field: 'rep_name', translationLabel: 'Employee' },
    { field: 'repTran_employeeID', translationLabel: 'Employee ID' },
    { field: 'repTran_shiftname', translationLabel: 'Shift' },
    { field: 'repTrans_amount', translationLabel: 'Initial amount' },
    { field: 'repTran_availableamount', translationLabel: 'Used amount' },
    { field: 'repTrans_closedate', translationLabel: 'Closing date' },
    { field: 'repTrans_accountNumber', translationLabel: 'Account Number' }
    //{ field: 'rep_load', translationLabel: 'Load' }
  ];
}
