import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnChanges
} from '@angular/core';

@Component({
  selector: 'app-mapping-grid',
  templateUrl: './mapping-grid.component.html',
  styleUrls: ['./mapping-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MappingGridComponent implements OnInit, OnChanges {
  @Input() data = [];
  @Input() columns = [];
  @Input() id;
  @Input() mappingWorkers = [];
  public tableRows = { name: '7', code: '7' };
  public rowsPerPageOptions;
  public showDataFrom: number;
  public showDataTo: number;
  public pageInfoMessage;
  public totalEntities;
  public filteredValue = [null];
  constructor() {}

  filteredValueExists(filteredValue) {
    if (!filteredValue) {
      return false;
    }
    // There is no value for this filter
    if (filteredValue.length === 0) {
      return true;
    }
    // Init state
    if (filteredValue.length === 1 && filteredValue[0] == null) {
      return false;
    }
    // Filter deleted
    if (filteredValue.length === this.totalEntities) {
      return false;
    }
    return true;
  }

  changePageInfoMessage(filteredValue) {
    if (this.filteredValueExists(filteredValue)) {
      this.pageInfoMessage = `Showing ${
        filteredValue.length === 0 ? '0' : this.showDataFrom
      } to ${
        filteredValue.length < this.showDataTo
          ? filteredValue.length
          : this.showDataTo
      } of ${filteredValue.length} entries (filtered from ${
        this.totalEntities
      } total entries)`;
    } else {
      this.pageInfoMessage = `Showing ${this.showDataFrom} to ${this.showDataTo} of ${this.totalEntities} entities`;
    }
    if (filteredValue.length === this.totalEntities) {
      this.pageInfoMessage = `Showing ${this.showDataFrom} to ${this.showDataTo} of ${this.totalEntities} entities`;
    }
  }
  onFilteredData({ filteredValue }) {
    this.showDataFrom = 1;
    this.filteredValue = [...filteredValue];
    this.changePageInfoMessage(filteredValue);
  }
  onDropDownChange(event) {
    this.tableRows = event.value;
    this.showDataFrom = 1;
    this.showDataTo = Number(this.tableRows.name);
    if (this.showDataTo > this.totalEntities) {
      this.showDataTo = this.totalEntities;
    }
    this.changePageInfoMessage(this.filteredValue);
  }
  paginate(event) {
    const { first } = event;
    this.showDataFrom = first + 1;
    this.showDataTo = this.showDataFrom - 1 + Number(this.tableRows.name);

    if (this.showDataTo > this.totalEntities) {
      this.showDataTo = this.totalEntities;
    }
    if (this.filteredValue.length > 0 && this.filteredValue[0] != null) {
      this.changePageInfoMessage(this.filteredValue);
    } else {
      this.pageInfoMessage = `Showing ${this.showDataFrom} to ${this.showDataTo} of ${this.totalEntities} entities`;
    }
    this.tableRows = this.tableRows;
  }
  ngOnInit() {
    this.rowsPerPageOptions = [
      { name: '5', code: '5' },
      { name: '7', code: '7' },
      { name: '10', code: '10' },
      { name: '15', code: '15' },
      { name: '25', code: '25' },
      { name: '50', code: '50' },
      { name: '100', code: '100' }
    ];
   
  }
  ngOnChanges() {
   
  }
  displayTable() {
    return this.data.length > 0 && this.columns.length > 0 ? true : false;
  }
}
