import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AnalyticsItem } from './analytics-report/model/analytics-item.model';
import { ConfigService } from '../shared/config/services/config.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { UserPreferencesService } from '../../modules/shared/preferences/user-preferences.service';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  public static RESOURCE_SERVICE = 'ResourceService';
  private analyticsReportArray: BehaviorSubject<
    AnalyticsItem[]
  > = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private userPreferencesService: UserPreferencesService
  ) {}

  getAnalyticsReportData(
    dateFrom: string,
    dateTo: string
  ): Observable<AnalyticsItem[]> {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${
      AnalyticsService.RESOURCE_SERVICE
    }/api/CustomerDashboardStats?DateFrom=${dateFrom}&DateTo=${dateTo}&customerCode=${customerCode}`;
    return this.http.get(address).pipe(
      map(jsonData => AnalyticsItem.fromJson(jsonData)),
      shareReplay(1)
    );
  }

  getAnalyticsReportDataFromApi(dateFrom, dateTo) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const DateFrom = moment(dateFrom).format('YYYY-MM-DD');
    const DateTo = moment(dateTo).format('YYYY-MM-DD');
    const params = new HttpParams().set('customerCode', customerCode);
    const address = `${this.configService.getBaseUrl()}${
      AnalyticsService.RESOURCE_SERVICE
    }/api/CustomerDashboardStats?DateFrom=${DateFrom}&DateTo=${DateTo}`;
    return  this.http
      .get<AnalyticsItem[]>(address, { params })
      .pipe(
        map(jsonData => {
          return AnalyticsItem.fromJson(jsonData);
        }),
        distinctUntilChanged(),
        shareReplay(1)
      )
      .subscribe(data => {
        this.setAnalyticsReportArray(data);
      });
  }

  setAnalyticsReportArray(data) {
    this.analyticsReportArray.next(data);
  }

  getAnalyticsReportArray() {
    return this.analyticsReportArray.asObservable();
  }
}
