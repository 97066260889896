export class ButtonItem{
    Code: string;
    DataType: number;
    IsDisabled: boolean;
    IsVisible: boolean;
    Title: string;

    constructor(item) {
        this.Code = item.Code;
        this.DataType = item.DataType;
        this.IsDisabled = item.IsDisabled;
        this.IsVisible = item.IsVisible;
        this.Title = item.Title;
    }

    static fromJson(data: any): ButtonItem[] {
        const buttonArray: ButtonItem[] = [];
        data.forEach(item => {
            buttonArray.push(new ButtonItem(item));
        });
        return buttonArray;
    }
}