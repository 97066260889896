import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import {
  filter,
  takeUntil,
  distinctUntilChanged,
  shareReplay,
  skipLast,
  skip,
  map
} from 'rxjs/operators';
import { ErrorService } from './modules/shared/error/services/error.service';
import { DialogService } from 'primeng/api';
import { ErrorDialogComponent } from './modules/shared/error/error-dialog/error-dialog.component';
import { NavigationEnd, Router } from '@angular/router';
import * as $ from 'jquery';
import { ErrorInterceptorService } from './modules/shared/interceptors/error-interceptor.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DialogService]
})
export class AppComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  visibleMenu = false;
  isLoginPage = false;
  private isSameError = false;

  constructor(
    private errorService: ErrorService,
    public dialogService: DialogService,
    private translate: TranslateService,
    private router: Router
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }

  ngOnInit(): void {

    if (environment.production) {
      if (location.protocol === 'http:') {
       window.location.href = location.href.replace('http', 'https');
      }
    }

    this.initializeErrors();
    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        if (
          event.url.indexOf('login') > -1 ||
          event.urlAfterRedirects.indexOf('login') > -1 ||
          event.url.indexOf('print') > -1 ||
          event.urlAfterRedirects.indexOf('print') > -1 ||
          event.url.indexOf('signup') > -1 ||
          event.urlAfterRedirects.indexOf('signup') > -1 ||
          event.url.indexOf('forgot-password') > -1 ||
          event.urlAfterRedirects.indexOf('forgot-password') > -1 ||
          event.url.indexOf('reset-password') > -1 ||
          event.urlAfterRedirects.indexOf('reset-password') > -1
        ) {
          this.onToggleElementsVisibility(true);
        } else {
          this.onToggleElementsVisibility(false);
        }
      });
  }

  sideBarClosed(event) {
    this.visibleMenu = event;
  }

  onOpenSidebarMenu() {
    this.visibleMenu = !this.visibleMenu;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private initializeErrors() {
    this.errorService
      .getErrors()
      .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe(errors => {
        if (errors !== null) {
          const isSameError = this.checkSameMessages(
            this.errorService.messages,
            errors[0]
          );
          if (errors[0] !== 'Wrong Username or Password.' && !isSameError) {
            this.dialogService.open(ErrorDialogComponent, {
              data: {
                errors: errors
              },
              header: this.translate.instant('APP.ERROR.DIALOG.HEADER'),
              width: '50%'
            });
          }
        }
      });
  }

  private onToggleElementsVisibility(shouldHide: boolean) {
    if (shouldHide) {
      this.isLoginPage = true;
      this.visibleMenu = false;
      $('.main-navigation').css('display', 'none');
      $('.ui-sidebar-left').css('display', 'none');
    } else {
      this.isLoginPage = false;
      $('.main-navigation').css('display', '');
      $('.ui-sidebar-left').css('display', '');
    }
  }

  checkSameMessages(messagesArray, error) {
    const messages = messagesArray.filter(message => message === error);
    return messages.length >= 2 ? true : false;
  }
}
