export class ShiftsItem {
        trancol_dailytranspk: string
        trancol_employeepk:string
        trancol_emplid: string
        trancol_name: string
        trancol_shiftpk: string
        trancol_shiftname: string
        trancol_jobpk: string
        trancol_jobid: string
        trancol_job: string
        trancol_clockedin: string
        trancol_clockedout: string
        trancol_totaltime: string
        trancol_ccsales: string
        trancol_cctips: string
        trancol_cashsales: string
        trancol_netsales: string
        trancol_winesales: string
        trancol_foodsales: string
        trancol_liquorsales: string
        trancol_cashtips: string
        trancol_servicecharge: string
        trancol_indirecttip: string
        trancol_jobgeneratesales: boolean
        trancol_shiftnumber: string
        trancol_overtime: string
        trancol_cctipfee: string
        trancol_PoolDefined: boolean
        trancol_salescat4: string
        trancol_salescat5: string
        trancol_salescat6: string
        trancol_salescat7: string
        trancol_additionaltips: string
        trancol_servicechargecc: string
        trancol_servicechargecash: string
        trancol_declaredcash: string
        trancol_commissionsales: string
        trancol_commissionsales_2: string
        trancol_totalbreaktime: string

    constructor(
        trancol_dailytranspk: string,
        trancol_employeepk:string,
        trancol_emplid: string,
        trancol_name: string,
        trancol_shiftpk: string,
        trancol_shiftname: string,
        trancol_jobpk: string,
        trancol_jobid: string,
        trancol_job: string,
        trancol_clockedin: string,
        trancol_clockedout: string,
        trancol_totaltime: string,
        trancol_ccsales: string,
        trancol_cctips: string,
        trancol_cashsales: string,
        trancol_netsales: string,
        trancol_winesales: string,
        trancol_foodsales: string,
        trancol_liquorsales: string,
        trancol_cashtips: string,
        trancol_servicecharge: string,
        trancol_indirecttip: string,
        trancol_jobgeneratesales: boolean,
        trancol_shiftnumber: string,
        trancol_overtime: string,
        trancol_cctipfee: string,
        trancol_PoolDefined: boolean,
        trancol_salescat4: string,
        trancol_salescat5: string,
        trancol_salescat6: string,
        trancol_salescat7: string,
        trancol_additionaltips: string,
        trancol_servicechargecc: string,
        trancol_servicechargecash: string,
        trancol_declaredcash: string,
        trancol_commissionsales: string,
        trancol_commissionsales_2: string,
        trancol_totalbreaktime: string) {
        
        this.trancol_dailytranspk = trancol_dailytranspk
        this.trancol_employeepk = trancol_employeepk
        this.trancol_emplid = trancol_emplid
        this.trancol_name = trancol_name
        this.trancol_shiftpk = trancol_shiftpk
        this.trancol_shiftname = trancol_shiftname
        this.trancol_jobpk = trancol_jobpk
        this.trancol_jobid = trancol_jobid
        this.trancol_job = trancol_job
        this.trancol_clockedin = trancol_clockedin
        this.trancol_clockedout = trancol_clockedout
        this.trancol_totaltime = trancol_totaltime
        this.trancol_ccsales = trancol_ccsales
        this.trancol_cctips = trancol_cctips
        this.trancol_cashsales = trancol_cashsales
        this.trancol_netsales = trancol_netsales
        this.trancol_winesales = trancol_winesales
        this.trancol_foodsales = trancol_foodsales
        this.trancol_liquorsales = trancol_liquorsales
        this.trancol_cashtips = trancol_cashtips
        this.trancol_servicecharge = trancol_servicecharge
        this.trancol_indirecttip = trancol_indirecttip
        this.trancol_jobgeneratesales = trancol_jobgeneratesales
        this.trancol_shiftnumber = trancol_shiftnumber
        this.trancol_overtime = trancol_overtime
        this.trancol_cctipfee = trancol_cctipfee
        this.trancol_PoolDefined = trancol_PoolDefined
        this.trancol_salescat4 = trancol_salescat4
        this.trancol_salescat5 = trancol_salescat5
        this.trancol_salescat6 = trancol_salescat6
        this.trancol_salescat7 = trancol_salescat7
        this.trancol_additionaltips = trancol_additionaltips
        this.trancol_servicechargecc = trancol_servicechargecc
        this.trancol_servicechargecash = trancol_servicechargecash
        this.trancol_declaredcash = trancol_declaredcash
        this.trancol_commissionsales = trancol_commissionsales
        this.trancol_commissionsales_2 = trancol_commissionsales_2
        this.trancol_totalbreaktime = trancol_totalbreaktime
    }
  
    static fromJson(data: any): ShiftsItem[] {
      const shiftsArray: ShiftsItem[] = [];
      data.forEach(item => {
        shiftsArray.push(new ShiftsItem(
          item.trancol_dailytranspk,
          item.trancol_employeepk,
          item.trancol_emplid,
          item.trancol_name,
          item.trancol_shiftpk,
          item.trancol_shiftname,
          item.trancol_jobpk,
          item.trancol_jobid,
          item.trancol_job,
          item.trancol_clockedin,
          item.trancol_clockedout,
          item.trancol_totaltime,
          item.trancol_ccsales,
          item.trancol_cctips,
          item.trancol_cashsales,
          item.trancol_netsales,
          item.trancol_winesales,
          item.trancol_foodsales,
          item.trancol_liquorsales,
          item.trancol_cashtips,
          item.trancol_servicecharge,
          item.trancol_indirecttip,
          item.trancol_jobgeneratesales,
          item.trancol_shiftnumber,
          item.trancol_overtime,
          item.trancol_cctipfee,
          item.trancol_PoolDefined,
          item.trancol_salescat4,
          item.trancol_salescat5,
          item.trancol_salescat6,
          item.trancol_salescat7,
          item.trancol_additionaltips,
          item.trancol_servicechargecc,
          item.trancol_servicechargecash,
          item.trancol_declaredcash,
          item.trancol_commissionsales,
          item.trancol_commissionsales_2,
          item.trancol_totalbreaktime ))
      });
      return shiftsArray;
    }
  }
  