import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-success-dialog',
    templateUrl: './success-dialog.component.html',
    styleUrls: ['./success-dialog.component.scss'],
})
export class SuccessDialogComponent implements OnInit {
    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() closeSuccessDialog: EventEmitter<{}> = new EventEmitter();
    @Input() visible = false;
    @Input() isSuccessButtonDisabled = false;
    @Input() buttonLabel = 'OK';
    @Input() description = 'This is description';
    @Input() descriptionSize = '15px';
    @Input() title = 'Success';
    @Input() titleSize = '24px';
    @Input() titleColor = 'black';
    @Input() showIcon = true;
    @Input() iconName = 'done';
    @Input() iconColor = 'green';
    @Input() iconSize = '24px';
    @Input() actionTemplate;
    @Input() headerTemplate;

    constructor() {}

    ngOnInit() {}

    onCloseSuccessDialog(): void {
        this.visible = false;
        this.visibleChange.emit(this.visible);
        this.closeSuccessDialog.emit({ closed: true });
    }
}
