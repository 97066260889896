import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../shared/config/services/config.service';
import { map, tap, shareReplay } from 'rxjs/operators';
import { AuthContext } from '../model/auth-context.model';
import { CustomersDto } from '../model/customers.dto';
import { Customers } from '../model/customers.model';
import { UserDetailsDto } from '../model/user-details.dto';
import { UserDetails } from '../model/user-details.model';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { UserPreferencesService } from '../../shared/preferences/user-preferences.service';
import { CustomerDataService } from '../../shared/customer/customer-data.service';

@Injectable()
export class AuthService {
  public static ADMIN_SERVICE = 'AdminService';
  public static RESOURCE_SERVICE = 'ResourceService';
  public authContext: AuthContext;
  public authContextLocalStorageKey = 'authContext';
  public forgotPasswordResponse = 'forgotPassword'; //-------------------------------------dodao
  public authContextObservable: Observable<AuthContext> = new Observable<
    AuthContext
  >();
  private authContextSubject: BehaviorSubject<AuthContext>;

  canGlobalSearch: boolean;
  globalSearchChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private userPreferencesService: UserPreferencesService,
    private customerDataService: CustomerDataService,
    private router: Router
  ) {
    this.authContextSubject = new BehaviorSubject<AuthContext>(
      this.getContextFromLocalStorage()
    );
    this.authContextObservable = this.authContextSubject.asObservable();

    this.globalSearchChanged.subscribe((value) => {
      this.canGlobalSearch = value;
    });

  }

  getToken(username: string, password: string): Observable<AuthContext> {
    return this.http
      .post<string>(
        `${this.configService.getBaseUrl()}${
        AuthService.ADMIN_SERVICE
        }/api/authenticate`,
        { Username: username, Password: password }
      )
      .pipe(
        map(token => {
          const authContext: AuthContext = new AuthContext({
            ChangePassUponLogin: false,
            ChooseOneCustomer: false,
            ShowUserAgreement: false,
            Location: '',
            Message: '',
            Name: '',
            Role: '',
            Success: true,
            Token: token,
            UserCode: 0,
            Customers: []
          });
          return authContext;
        }),

        tap(authContext =>
          localStorage.setItem(
            this.authContextLocalStorageKey,
            JSON.stringify(authContext)
          )
        ),
        shareReplay(1)
      );
    //tap((authContext) => this.authContextSubject.next(authContext)));
  }

  public getCanUserUseGlobalSearch() {

   this.http
      .get(
        `${this.configService.getBaseUrl()}${
        AuthService.ADMIN_SERVICE
        }/api/GlobalConfiguration`
      )
      .subscribe(json => {
          var canUseGlobalSearch = json["GlobalLocationSearch"];
          this.globalSearchChanged.next(canUseGlobalSearch);
        });

  }

  public GetCustomers() {
    return (
      this.http
        .get<CustomersDto>(
          `${this.configService.getBaseUrl()}${
          AuthService.ADMIN_SERVICE
          }/api/Account/GetActiveCustomers`
        )
        .pipe(
          map(customersJSON => {
            const customers: Customers = new Customers(customersJSON);
            const authContext: AuthContext = this.getContextFromLocalStorage();
            return this.setCustomers(authContext, customers);
          }),
          tap(authContext =>
            localStorage.setItem(
              this.authContextLocalStorageKey,
              JSON.stringify(authContext)
            )
          ),
          tap(authContext => this.authContextSubject.next(authContext)),
          shareReplay(1)
        )
    );
  }

  public getAccountDetails() {
    return this.http
      .get<UserDetailsDto>(
        `${this.configService.getBaseUrl()}${
        AuthService.ADMIN_SERVICE
        }/api/AccountDetails`
      )
      .pipe(
        map(userDetailsJSON => {
          const userDetails: UserDetails = new UserDetails(userDetailsJSON);
          const authContext: AuthContext = this.getContextFromLocalStorage();
          return this.setUserDetails(authContext, userDetails);
        }),

        tap(authContext =>
          localStorage.setItem(
            this.authContextLocalStorageKey,
            JSON.stringify(authContext)
          )
        ),
        tap(authContext => this.authContextSubject.next(authContext)),
        shareReplay(1)
      );
  }
  public getUserDetails() {
    return this.http
      .get(
        `${this.configService.getBaseUrl()}${
        AuthService.ADMIN_SERVICE
        }/api/Account/GetUserDetails`
      )
      .pipe(
        map(userDetailsJSON => {
          const userDetails: UserDetails = new UserDetails(userDetailsJSON);
          const authContext: AuthContext = this.getContextFromLocalStorage();
          return this.setUserDetails(authContext, userDetails);
        }),
        tap(authContext =>
          localStorage.setItem(
            this.authContextLocalStorageKey,
            JSON.stringify(authContext)
          )
        ),
        tap(authContext => this.authContextSubject.next(authContext)),
        tap(authContext =>
          this.userPreferencesService.setCustomerLocations(
            authContext ? [authContext.Customers[0]] : [{}]
          )
        ),
        tap(authContext =>
          this.customerDataService.setData(
            authContext ? [authContext.Customers[0]] : null
          )
        ),
        shareReplay(1)
      );
  }
  public setUserDetails(
    authContext: AuthContext,
    userDetails: UserDetails
  ): AuthContext {
    /** We need to add appropriate get user details method
     * Missing change pass uponj login, role name ....
     */
    authContext.Name = userDetails.Name;
    authContext.Role = userDetails.Role;
    authContext.Location = userDetails.Location;
    return authContext;
  }

  public setCustomers(
    authContext: AuthContext,
    customers: Customers
  ): AuthContext {
    authContext.UserCode = customers.UserCode;
    const sortedCustomers = _.orderBy(customers.Customers, ['Name'], ['asc']);
    authContext.Customers = sortedCustomers;
    return authContext;
  }

  public getContextFromLocalStorage(): AuthContext {
    return JSON.parse(localStorage.getItem(this.authContextLocalStorageKey));
  }

  public isAuthenticated() {
    const authContext = this.getContextFromLocalStorage();
    return authContext && authContext.Success && authContext.Token;
  }

  public logout() {
    localStorage.removeItem(this.authContextLocalStorageKey);
    localStorage.removeItem('customers');
    localStorage.removeItem('menu');
    localStorage.removeItem('page');
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  public getAuthDetailsData() {
    return this.authContextObservable;
  }

  forgotPassword(emailOrPhone: string, username: string): Observable<string> {
    return this.http
      .post<string>(
        `${this.configService.getBaseUrl()}${
        AuthService.ADMIN_SERVICE
        }/api/Account/ForgotPassword`,
        { PhoneOrEmal: emailOrPhone, Username: username }
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  resetPassword(resetCode: string, password: string): Observable<string> {
    return this.http
      .post<string>(
        `${this.configService.getBaseUrl()}${
        AuthService.ADMIN_SERVICE
        }/api/Account/ResetPassword`,
        { ResetCode: resetCode, Password: password }
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  RegisterAccount(UserDetails) {
    
    return this.http
    .post<string>(
      `${this.configService.getBaseUrl()}${
        AuthService.ADMIN_SERVICE
      }/api/v2/RegisterAccount`,
      UserDetails 
    )
    .pipe(
      map(response => {
        return response;
      })
    );

  }
 
}
