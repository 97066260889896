import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './views/login/login.component';
import {ForgotPasswordComponent} from './views/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './views/reset-password/reset-password.component';
import {TestComponent} from './views/test/test.component';
import {PrintPageComponent} from './views/print/print-page.component';
import {AuthGuard} from './modules/auth/services/auth-guard.service';
import {AuthSkipLogin} from './modules/auth/services/auth-skip-login.service';
import {SignupComponent} from './views/signup/signup.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthSkipLogin]
  },
  {
    path: 'dashboard',
    loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [AuthSkipLogin]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [AuthSkipLogin]
  },
  {
    path: 'reporting',
    loadChildren: './modules/reporting/reporting.module#ReportingModule',
  },
  {
    path: 'control_panel',
    loadChildren: './modules/control-panel/control-panel.module#ControlPanelModule',
  },
  {
    path: 'time_and_attendance',
    loadChildren: './modules/time/time.module#TimeModule',
  },
  {
    path: 'help',
    loadChildren: './modules/help/help.module#HelpModule',
  },
  {
    path: 'setup',
    loadChildren: './modules/setup/setup.module#SetupModule',
  },
  {
    path: 'test',
    component: TestComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'print',
    component: PrintPageComponent,
    canActivate: [AuthGuard]

  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
