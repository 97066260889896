import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  public messages = [];
  public errors = new ReplaySubject<string[]>(1);

  constructor() {}

  public addErrors(errors: string[]): void {
    this.errors.next(errors);
  }

  public getErrors() {
    return this.errors.asObservable();
  }
}
