import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthSkipLogin implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = this.authenticationService.isAuthenticated();

    // #5891 requested disabling of auto login.

    /*if (isAuthenticated) {
      this.router.navigate(['/dashboard/daily-status']);
    }*/

    return true;
  }
}
