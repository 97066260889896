import { Component, OnInit, Input,ViewEncapsulation } from '@angular/core';
import { DatatableColumn } from '../model/datatable-header-column.model';
import { TranslateService } from '@ngx-translate/core';
import { Column } from 'primeng/shared';
import { ColumnChooserService } from '../services/column-chooser.service';
import { DashboardService } from 'src/app/modules/navigation/services/dashboard.service';
import { TimeCard } from '../../../dashboard/models/time-card.model';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { RowsPerPageItem } from '../../../reporting/models/rows-per-page.model';
import { TableRowItem } from '../model/table-row.model';

@Component({
  selector: 'app-column-chooser',
  templateUrl: './column-chooser.component.html',
  styleUrls: ['./column-chooser.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ColumnChooserComponent implements OnInit {

  @Input() data = [];
  @Input() columns = [];
  @Input() showColumnChoser = false;
  @Input() showGlobalFilter = false;
  @Input() expandExtraTable = false;
  @Input() timeCardsTable = false;
  @Input() showDeleteInExtraTable = false;
  @Input() deleteTimeCards: Function;
  @Input() btnClickCallApi = false;
  @Input() showEditButton = false;
  @Input() showDetailsButton = false;
  @Input() showPlusOrMinusButtons = true;
  @Input() getEmployeeTipOut: Function;
  public saveOptions: SelectItem[];
  public selectedColumns: DatatableColumn[];
  public tableRows;
  public rowsPerPageOptions: TableRowItem[];
  public showDataFrom: number;
  public showDataTo: number;
  public totalEntities: number;

  private displayedColumns;
  private selectedRowIndex:Number;
  private hoveredRowIndex:Number;
  private showExpandedElement = false;
  private indexOfExpandedElement:Number;
  private showDeleteConfirmation = false;
  private hardcodedSelectedDate = '2018-01-22'; //this should be reworked when calendar function is merged with this branch
  
  constructor(
    private translationService: TranslateService,
    private columnChooserService: ColumnChooserService,
    private dashboardService: DashboardService) {
      this.saveOptions = [
        { label: 'Csv', value: 'Csv' },
        { label: 'Excel', value: 'Excel' }
      ];
  }

  ngOnInit() {
    this.totalEntities = this.data.length;
    this.tableRows =  {name: '7', code: 7};
    this.showDataFrom = 1;
    this.showDataTo = this.totalEntities;
    if(this.showDataTo > this.totalEntities){
      this.showDataTo = this.totalEntities;
    }
    this.rowsPerPageOptions = this.getRowsPerPageOptions(this.totalEntities);
    this.displayedColumns = this.columns;
    this.selectedColumns = this.columns;
  }

  getRowsPerPageOptions(totalEntities){
    const rowsPerPage = [ {name: '5', code: 5}, {name: '7', code: 7}, {name: '10', code: 10}, {name: '15', code: 15}, {name: '25', code: 25}, {name: '50', code: 50}, {name: '100', code: 100}]
    return rowsPerPage;
  }

  selectRowData(rowData, rowIndex) {
    if(rowIndex != this.indexOfExpandedElement){
      this.showExpandedElement = true;
    }else{
      this.showExpandedElement = !this.showExpandedElement;
    }
    this.indexOfExpandedElement = rowIndex;
    this.selectedRowIndex = rowIndex;
    if(!this.showExpandedElement){
      this.indexOfExpandedElement = -1;
    }
    if(this.btnClickCallApi){
      this.getTimeCardsWebCall(rowData);
    }
  }

  getTimeCardsWebCall(rowData){
    const { employee_id } = rowData;
    this.columnChooserService.isRowSelected.next(true);
    this.columnChooserService.selectRowData(rowData);
    this.dashboardService.getTimeCardsFromSelectedRow(this.hardcodedSelectedDate, employee_id).subscribe(data => {
      this.dashboardService.cardsTableData.next(data);
    });
  }

  changeStyle($event, rowIndex){
    if($event.type === 'mouseover'){
      this.hoveredRowIndex = rowIndex;
    }
  }

  delete(rowData){
    this.showDeleteConfirmation = true;
    this.dashboardService.showDeleteConfirmation.next(true);
    this.deleteTimeCards(rowData);
  }

  details(rowData){
    const employeeId =  rowData.repAudit_employeeID;
    const shiftId =  rowData.repAudit_shiftID;
    const name = rowData.repAudit_name;
    this.getEmployeeTipOut(employeeId, shiftId, name);
  }

  paginate(event) {
    const { first } = event;
    this.showDataFrom = first + 1;
    this.showDataTo = (this.showDataFrom - 1) + Number(this.tableRows.code);
    if(this.showDataTo > this.totalEntities){
      this.showDataTo = this.totalEntities;
    }
  }

  onDropDownChange(event){
    this.tableRows = event.value;
  }

  ngOnDestroy(){
  }
}
