export class NavigationChild {
  id: string;
  label: string;
  icon: string;
  route: string;
  active ? = false;

  constructor(id: string, label: string, icon: string, route?: string) {
    this.id = id;
    this.label = label;
    this.icon = icon;
    this.route = route;
  }
}
