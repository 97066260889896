import { UserDetailsDto } from './user-details.dto';

export class UserDetails implements UserDetailsDto {
    ChangePassUponLogin: Boolean;
    Location: string;
    Name: string;
    Role: string;
    ShowUserAgreement: Boolean;
    UserCode: Number;
    PossibleRoles: Array<any>;

    constructor(json) {
        Object.assign(this, json);
        this.PossibleRoles = [];
    }
}
