import { ButtonItem } from './button-item.model';
import { TabItem } from './tab-item.model';
import * as _ from 'lodash';

export class PageItem {
  Buttons: ButtonItem[];
  Tabs: TabItem[];

  constructor(item) {
    if (!_.isEmpty(item)) {
      this.Buttons = ButtonItem.fromJson(item.Buttons);
      this.Tabs = TabItem.fromJson(item.Tabs);
    }
  }

  static fromJson(data: any): PageItem[] {
    const pageArray: PageItem[] = [];
    data.forEach(item => {
      pageArray.push(new PageItem(item));
    });
    return pageArray;
  }
}
