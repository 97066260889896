export class EmployeePoolDistributionFroAllCustomers {
    CurrentTipOut: any;
    Exception: any;
    Fk_CalculationDef: any;
    Fk_PoolDistribution: any;
    OnlyFixedAmount: any;
    OnlyFromDistributionRules: any;
    PG_From: any;
    PG_To: any;
    PG_To_Split: any;
    Percentage: any;
    PercentageFrom: any;
    PercentageFrom_ID: any;
    TipAmountIsPercentage: any;

    constructor(
        CurrentTipOut,
        Exception,
        Fk_CalculationDef,
        Fk_PoolDistribution,
        OnlyFixedAmount,
        OnlyFromDistributionRules,
        PG_From,
        PG_To,
        PG_To_Split,
        Percentage,
        PercentageFrom,
        PercentageFrom_ID,
        TipAmountIsPercentage,
    ) {
        this.CurrentTipOut = CurrentTipOut;
        this.Exception = Exception;
        this.Fk_CalculationDef = Fk_CalculationDef;
        this.Fk_PoolDistribution = Fk_PoolDistribution;
        this.OnlyFixedAmount = OnlyFixedAmount;
        this.OnlyFromDistributionRules = OnlyFromDistributionRules;
        this.PG_From = PG_From;
        this.PG_To = PG_To;
        this.PG_To_Split = PG_To_Split;
        this.Percentage = Percentage;
        this.PercentageFrom = PercentageFrom;
        this.PercentageFrom_ID = PercentageFrom_ID;
        this.TipAmountIsPercentage = TipAmountIsPercentage;
    }

    static fromJson(data: any): EmployeePoolDistributionFroAllCustomers[] {
        const poolArray: EmployeePoolDistributionFroAllCustomers[] = [];
        data.forEach(pool => {
            poolArray.push(new EmployeePoolDistributionFroAllCustomers(
                pool.CurrentTipOut,
                pool.Exception,
                pool.Fk_CalculationDef,
                pool.Fk_PoolDistribution,
                pool.OnlyFixedAmount,
                pool.OnlyFromDistributionRules,
                pool.PG_From,
                pool.PG_To,
                pool.PG_To_Split,
                pool.Percentage,
                pool.PercentageFrom,
                pool.PercentageFrom_ID,
                pool.TipAmountIsPercentage,
            ));
        });

        return poolArray;
    }
}