import { Component, OnInit, Input } from '@angular/core';
import { AnalyticsItem } from '../analytics-report/model/analytics-item.model';
import { Message } from 'primeng/primeng';
import { log } from 'util';

@Component({
  selector: 'app-analytics-report-item',
  templateUrl: './analytics-report-item.component.html',
  styleUrls: ['./analytics-report-item.component.scss']
})
export class AnalyticsReportItemComponent implements OnInit {
  @Input() analyticsItem: AnalyticsItem;
  public data: any;
  public options: {};
  public percentage: any = 0;
  public percentageBorder: any = 0;
  colors = [
    'red',
    'blue',
    'green',
    'orange',
    'purple',
    'yellow',
    'black',
    'brown',
    'pink',
    '#fcbc0c',
    'lightblue',
    'lightgreen',
    '#0091ea',
    '#80d8ff',
    '#8d3ce7',
    '#3c1e6e'
  ];

  constructor() {}

  ngOnInit() {
    const analyticsItem = this.analyticsItem;
    this.options = { cutoutPercentage: 70 };
    if (analyticsItem) {
      const analyticsItemPerIsZero = String(analyticsItem.per) === '0';
      const firstValueOfChart = Number(
        analyticsItem.per
      );
      const secondValueOfChart = 100 - Number(firstValueOfChart);
      this.percentage = firstValueOfChart;
      this.percentageBorder = firstValueOfChart > 100 ? 100 : firstValueOfChart;
      const randomNumber = Math.floor(Math.random() * this.colors.length) + 1;
      const selectedColor = this.colors[randomNumber];
      this.data = {
        datasets: [
          {
            label: this.analyticsItem.code,
            data: [firstValueOfChart, secondValueOfChart],
            fill: true,
            percent: firstValueOfChart,
            backgroundColor: [
              analyticsItemPerIsZero ? '#EEE' : selectedColor,
              '#EEE'
            ],
            weight: 1
          }
        ]
      };
    }
  }
}
