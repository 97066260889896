import {Injectable} from '@angular/core';
import {
  map,
  distinctUntilChanged,
  takeUntil,
  shareReplay,
  combineAll,
  withLatestFrom
} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConfigService} from '../../shared/config/services/config.service';
import {UserPreferencesService} from '../../shared/preferences/user-preferences.service';
import {Observable, BehaviorSubject, forkJoin, combineLatest, Subject} from 'rxjs';
import {CheckOutsItem} from '../models/check-outs.model';
import {ShiftsItem} from '../../reporting/models/shifts.model';
import * as moment from 'moment';
import {CustomerEmployee} from '../models/customer-employee.model';
import {CustomerJobs} from '../models/customer-jobs.model';
import {EmployeePoolDistributionFroAllCustomers} from '../models/employee-pool-distribution-for-all-customers.model';
import {EmployeePoolDistribution} from '../models/employee-pool-distribution.model';
import {ShiftsService} from './shifts.service';

@Injectable({
  providedIn: 'root'
})
export class CheckOutsService {
  public eventClicker: Subject<void> = new Subject<void>();
  public isOverrideButtonVisable = false;
  public isEditButtonDisabled = true;
  public sumCheckOutArray;
  public static RESOURCE_SERVICE = 'ResourceService';
  private shiftsDataArray: BehaviorSubject<any> = new BehaviorSubject([]);
  private shiftsEmployeeJobsArray: BehaviorSubject<any> = new BehaviorSubject(
    []
  );
  private employeePoolDistributionRulesArray: BehaviorSubject<any> = new BehaviorSubject([]);
  private checkoutsDataWithShifts = [];
  private shiftBuilt = true;
  // private checkOutsDataRow;
  private data = [];
  private shiftAdjustmentFormDataSubject: BehaviorSubject<{}> = new BehaviorSubject(
    {}
  );

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private userPreferencesService: UserPreferencesService,
    private shiftService: ShiftsService
  ) {
  }

  getAuditDetailsCheckOutsData(businessDate: string): Observable<ShiftsItem[]> {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${
      CheckOutsService.RESOURCE_SERVICE
    }/api/CustomerShifts?businessDate=${businessDate}&customerCode=${customerCode}`;
    return this.http
      .get(address)
      .pipe(map(jsonData => ShiftsItem.fromJson(jsonData)));
  }

  getCheckOutsData(businessDate: string): Observable<CheckOutsItem[]> {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${
      CheckOutsService.RESOURCE_SERVICE
    }/api/CustomerShifts?businessDate=${businessDate}&customerCode=${customerCode}`;
    return this.http.get(address).pipe(
      map(jsonData =>
        CheckOutsItem.fromJson(jsonData)),
      shareReplay(1)
    );


  }

  getShiftsDataRow(businessDate, callback) {
    const BusinessDate = moment(businessDate).format('YYYY-MM-DD');
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${
      CheckOutsService.RESOURCE_SERVICE
    }/api/CustomerCheckOuts?businessDate=${BusinessDate}&customerCode=${customerCode}`;
    this.http
      .get<any>(address)
      .pipe(
        shareReplay(1),
        map(data => this.shiftService.mergeAndSortApiDataWithShifts(CheckOutsItem.fromJson(data))
        )
      )
      .subscribe(data => {
        this.setShiftDataArray(data);
        callback();
      });
  }

  getEmployeePoolDistributionRules(date, employeeId, jobId, shiftId, callback) {
    const businessDate = moment(date).format('YYYY-MM-DD');
    const customerCode = this.userPreferencesService.getCustomerCode();
    const params = new HttpParams()
      .set('customerCode', customerCode)
      .set('businessDate', businessDate)
      .set('jobId', jobId)
      .set('employeeId', employeeId)
      .set('shiftId', shiftId);
    const address = `${this.configService.getBaseUrl()}${
      CheckOutsService.RESOURCE_SERVICE
    }/api/EmployeePoolDistributionRules`;
    this.http
      .get<EmployeePoolDistribution[]>(address, {params})
      .pipe(
        distinctUntilChanged(),
        map(jsonData => EmployeePoolDistribution.fromJson(jsonData))
      )
      .subscribe({
        next: data => {
          this.setEmployeePoolDistributionRulesArray(data);
          callback();
        },
        error: e => {
          callback(e);
        },
        complete: () => {
        }
      });
  }

  saveBatchEdit(body, callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}ResourceService/api/CustomerCheckOuts/BatchEdit?customerCode=${customerCode}`;

    body.ModelList.forEach(el => {
      const splitted = el.TotalTime.split(':');
      const minutes = (+splitted[0]) * 60 + (+splitted[1]);
      el.TotalTime = minutes;
    });

    this.http.post(address, body).subscribe({
      next: response => {
        callback(response);
      },
      error: e => {
        callback(e);
      }
    });
  }

  saveEmployeePoolDistributionRules(body, callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}ResourceService/api/EmployeePoolDistributionRules?customerCode=${customerCode}`;
    this.http.put(address, body).subscribe({
      next: response => {
        callback(response);
      },
      error: e => {
        callback(e);
      }
    });
  }

  getCustomerEmployees(): Observable<CustomerEmployee[]> {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const params = new HttpParams().set('customerCode', customerCode);
    const address = `
    ${this.configService.getBaseUrl()}ResourceService/api/CustomerEmployees`;
    return this.http.get<CustomerEmployee[]>(address, {params}).pipe(
      map(jsonData => {
        return CustomerEmployee.fromJson(jsonData);
      })
    );
  }

  getCustomerJobs(date): Observable<CustomerJobs[]> {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const params = new HttpParams()
      .set('customerCode', customerCode)
      .set('businessDate', date);
    const address = `
    ${this.configService.getBaseUrl()}ResourceService/api/CustomerJobs`;
    return this.http.get<CustomerJobs[]>(address, {params}).pipe(
      map(jsonData => {
        return CustomerJobs.fromJson(jsonData);
      })
    );
  }

  getCustomerShifts(date): Observable<any> {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${
      CheckOutsService.RESOURCE_SERVICE
    }/api/CustomerShifts?businessDate=${date}&customerCode=${customerCode}`;
    return this.http.get<any>(address);
  }

  getEmployeePoolDistributionRulesForAllCustomers(date, shift_PK) {
    const businessDate = moment(date).format('YYYY-MM-DD');
    const customerCode = this.userPreferencesService.getCustomerCode();
    const params = new HttpParams()
      .set('customerCode', customerCode)
      .set('businessDate', businessDate)
      .set('shift_PK', shift_PK);
    const address = `${this.configService.getBaseUrl()}${
      CheckOutsService.RESOURCE_SERVICE
    }/api/PoolDistributionRules`;
    return this.http
      .get<EmployeePoolDistributionFroAllCustomers[]>(address, {params})
      .pipe(
        map(jsonData => {
          return EmployeePoolDistributionFroAllCustomers.fromJson(jsonData);
        })
      );
  }

  getCustomerEmployeesAndJobsFromApi(date, shift_PK, destroy, callback) {
    const BusinessDate = moment(date).format('YYYY-MM-DD');
    forkJoin(
      this.getCustomerShifts(BusinessDate),
      this.getCustomerEmployees(),
      this.getCustomerJobs(BusinessDate),
      this.getEmployeePoolDistributionRulesForAllCustomers(
        BusinessDate,
        shift_PK
      )
    )
      .pipe(
        takeUntil(destroy),
        shareReplay(),
        distinctUntilChanged()
      )
      .subscribe({
        next: data => {
          this.setShiftsEmployeeJobsArray(data);
          callback(data);
        },
        error: e => {
          callback(e);
        }
      });
  }

  saveDistribution(body, callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${
      CheckOutsService.RESOURCE_SERVICE
    }/api/PoolDistributionRules?customerCode=${customerCode}`;
    this.http.put(address, body).subscribe({
      next: () => {
        callback();
      },
      error: e => {
        callback(e);
      }
    });
  }

  saveShiftForm(body, callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${
      CheckOutsService.RESOURCE_SERVICE
    }/api/CustomerCheckOuts/BatchEdit?customerCode=${customerCode}`;
    this.http.post(address, body).subscribe({
      next: () => {
        callback();
      },
      error: e => {
        callback(e);
      }
    });
  }

  saveEditForm(body, callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${
      CheckOutsService.RESOURCE_SERVICE
    }/api/CustomerCheckOuts/Edit?customerCode=${customerCode}`;
    this.http.post(address, body).subscribe({
      next: () => {
        callback();
      },
      error: e => {
        callback(e);
      }
    });
  }

  getOpenShift(date, shiftId): Observable<any> {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const params = new HttpParams()
      .set('customerCode', customerCode)
      .set('businessDate', date)
      .set('shiftId', shiftId);
    const address = `${this.configService.getBaseUrl()}${
      CheckOutsService.RESOURCE_SERVICE
    }/api/CustomerOpenShift`;
    return this.http.get<any>(address, {params});
  }

  checkoutOpenShift(date, shiftId, body, callback): Observable<any> {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${
      CheckOutsService.RESOURCE_SERVICE
    }/api/CustomerOpenShift?customerCode=${customerCode}`;
    return this.http.put<any>(address, body);
  }

  onOpenCheckOutShift(date, shiftId, body, callback) {
    this.checkoutOpenShift(date, shiftId, body, callback)
      .pipe(withLatestFrom(this.getOpenShift(date, shiftId)))
      .subscribe({
        next: ([responseFromPut, responseFromGet]) => {
          callback();
        },
        error: e => callback(e)
      });
  }

  getClosedShift(date, shiftId) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const params = new HttpParams()
      .set('customerCode', customerCode)
      .set('businessDate', date)
      .set('shiftId', shiftId);
    const address = `${this.configService.getBaseUrl()}${
      CheckOutsService.RESOURCE_SERVICE
    }/api/CustomerCloseShift?`;
    return this.http.get<any>(address, {params});
  }

  checkOutClosedShift(BusinessDate, ShiftID, ProcessID) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const body = {BusinessDate, ShiftID, ProcessID, customerCode};
    const address = `${this.configService.getBaseUrl()}${
      CheckOutsService.RESOURCE_SERVICE
    }/api/CustomerCloseShift?customerCode=${customerCode}`;
    return this.http.put<any>(address, body);
  }

  onClosedShift(date, shiftId, callback) {
    this.getClosedShift(date, shiftId)
      .pipe(shareReplay())
      .subscribe({
        next: secondResponse => {
          this.checkOutClosedShift(
            date,
            shiftId,
            secondResponse.ProcessID
          ).subscribe({
            next: () => {
              callback();
            },
            error: e => callback(e)
          });
        }
      });
  }

  setShiftDataArray(data) {
    this.shiftsDataArray.next(data);
  }

  sumCheckouts(data) {
    this.sumCheckOutArray = {};
    this.sumCheckOutArray['trancol_netsales'] = 0;
    this.sumCheckOutArray['trancol_foodsales'] = 0;
    this.sumCheckOutArray['trancol_additionaltips'] = 0;
    this.sumCheckOutArray['trancol_servicechargecc'] = 0;
    this.sumCheckOutArray['trancol_cashtips'] = 0;
    this.sumCheckOutArray['trancol_cashsales'] = 0;
    this.sumCheckOutArray['trancol_winesales'] = 0;
    this.sumCheckOutArray['trancol_liquorsales'] = 0;
    this.sumCheckOutArray['trancol_cctipfee'] = 0;
    this.sumCheckOutArray['trancol_servicechargecash'] = 0;
    data.reduce((total, element) => {
      total['trancol_netsales'] += element['trancol_netsales'];
      total['trancol_foodsales'] += element['trancol_foodsales'];
      total['trancol_additionaltips'] += element['trancol_additionaltips'];
      total['trancol_servicechargecc'] += element['trancol_servicechargecc'];
      total['trancol_cashtips'] += element['trancol_cashtips'];
      total['trancol_cashsales'] += element['trancol_cashsales'];
      total['trancol_winesales'] += element['trancol_winesales'];
      total['trancol_liquorsales'] += element['trancol_liquorsales'];
      total['trancol_cctipfee'] += element['trancol_cctipfee'];
      total['trancol_servicechargecash'] +=
        element['trancol_servicechargecash'];
      return total;
    }, this.sumCheckOutArray);

    this.sumCheckOutArray['trancol_netsales'] = this.sumCheckOutArray[
      'trancol_netsales'
      ].toFixed(2);
    this.sumCheckOutArray['trancol_foodsales'] = this.sumCheckOutArray[
      'trancol_foodsales'
      ].toFixed(2);
    this.sumCheckOutArray['trancol_additionaltips'] = this.sumCheckOutArray[
      'trancol_additionaltips'
      ].toFixed(2);
    this.sumCheckOutArray['trancol_servicechargecc'] = this.sumCheckOutArray[
      'trancol_servicechargecc'
      ].toFixed(2);
    this.sumCheckOutArray['trancol_cashtips'] = this.sumCheckOutArray[
      'trancol_cashtips'
      ].toFixed(2);
    this.sumCheckOutArray['trancol_cashsales'] = this.sumCheckOutArray[
      'trancol_cashsales'
      ].toFixed(2);
    this.sumCheckOutArray['trancol_winesales'] = this.sumCheckOutArray[
      'trancol_winesales'
      ].toFixed(2);
    this.sumCheckOutArray['trancol_liquorsales'] = this.sumCheckOutArray[
      'trancol_liquorsales'
      ].toFixed(2);
    this.sumCheckOutArray['trancol_cctipfee'] = this.sumCheckOutArray[
      'trancol_cctipfee'
      ].toFixed(2);
    this.sumCheckOutArray['trancol_servicechargecash'] = this.sumCheckOutArray[
      'trancol_servicechargecash'
      ].toFixed(2);

    return this.sumCheckOutArray;
  }

  setEmployeePoolDistributionRulesArray(data) {
    this.employeePoolDistributionRulesArray.next(data);
  }

  getEmployeePoolDistributionRulesArray() {
    return this.employeePoolDistributionRulesArray.asObservable();
  }

  getShiftDataArray() {
    return this.shiftsDataArray.asObservable();
  }

  setShiftsEmployeeJobsArray(data) {
    this.shiftsEmployeeJobsArray.next(data);
  }

  getShiftsEmployeeJobsArray() {
    return this.shiftsEmployeeJobsArray.asObservable();
  }

  findCurrentTab(Tabs, nameOfTabs) {
    return Tabs.findIndex(tab => tab.Code === nameOfTabs);
  }

  getEditButton() {
    return this.isEditButtonDisabled;
  }

  setEditButton(value) {
    this.isEditButtonDisabled = value;
  }

  getIsOverrideButtonVisable() {
    return this.isOverrideButtonVisable;
  }

  setIsOverrideButtonVisable(value) {
    this.isOverrideButtonVisable = value;
  }

  get shiftAdjustmentFormData() {
    return this.shiftAdjustmentFormDataSubject.asObservable();
  }

  get shiftAdjustmenFormValue() {
    return this.shiftAdjustmentFormDataSubject.getValue();
  }

  setShiftAdjustmentFormData(adjustmenForm) {
    this.shiftAdjustmentFormDataSubject.next(adjustmenForm);
  }
}
