export class CustomerJobs {
    Job_ID: any;
    Name: any;
    constructor(
        Job_ID,
        Name,
    ) {
        this.Job_ID = Job_ID,
            this.Name = Name
    }

    static fromJson(data: any): CustomerJobs[] {
        const employeeJob: CustomerJobs[] = [];
        data.forEach(e => {
            employeeJob.push(
                new CustomerJobs(
                    e.Job_ID,
                    e.Name,
                )
            );
        });
        return employeeJob;
    }
}
