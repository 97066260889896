export class EmployeePoolDistribution {
    Employee_ID: any;
    Exception: any;
    Job_ID: any;
    Percentage: any;
    Percentage_From: any;
    Percentage_ID: any;
    Shift_ID: any;
    TipPoolGrFrom_ID: any;
    TipPoolGrTo_ID: any;
    TipPoolGrTo_Name: any;

    constructor(
        Employee_ID,
        Exception,
        Job_ID,
        Percentage,
        Percentage_From,
        Percentage_ID,
        Shift_ID,
        TipPoolGrFrom_ID,
        TipPoolGrTo_ID,
        TipPoolGrTo_Name
    ) {
        this.Employee_ID = Employee_ID;
        this.Exception = Exception;
        this.Job_ID = Job_ID;
        this.Percentage = Percentage;
        this.Percentage_From = Percentage_From;
        this.Percentage_ID = Percentage_ID;
        this.Shift_ID = Shift_ID;
        this.TipPoolGrFrom_ID = TipPoolGrFrom_ID;
        this.TipPoolGrTo_ID = TipPoolGrTo_ID;
        this.TipPoolGrTo_Name = TipPoolGrTo_Name
    }

    static fromJson(data: any): EmployeePoolDistribution[] {
        const poolArray: EmployeePoolDistribution[] = [];
        data.forEach(pool => {
           poolArray.push(new EmployeePoolDistribution(
            pool.Employee_ID,
            pool.Exception,
            pool.Job_ID,
            pool.Percentage,
            pool.Percentage_From,
            pool.Percentage_ID,
            pool.Shift_ID,
            pool.TipPoolGrFrom_ID,
            pool.TipPoolGrTo_ID,
            pool.TipPoolGrTo_Name
           ));
        });

        return poolArray;
    }
}