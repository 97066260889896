import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';



@Injectable({
      providedIn: 'root'
})

export class ColumnChooserService {

      public isRowSelected: BehaviorSubject<boolean> = new BehaviorSubject(false);
      public isRowSelectedObservable = this.isRowSelected.asObservable();
      private selectedRowData: BehaviorSubject<Object> = new BehaviorSubject({});
      private selectedRowDataObservable = this.selectedRowData.asObservable();


      constructor() { }

      selectRowData(data: object) {
            this.selectedRowData.next(data);
      }

      getSelectedRowData(): Observable<Object> {
            return this.selectedRowDataObservable;
      }
}