export class ShiftsItem {
    Closed: any;
    ndTIme: any;
    Name: any;
    Number: any;
    Shift_ID:any;
    SoftShift: any;
    StartTime: any;
  
    constructor(
        Closed,
        ndTIme,
        Name,
        Number,
        Shift_ID,
        SoftShift,
        StartTime
    ) {
      this.Closed = Closed;
      this.ndTIme = ndTIme;
      this.Name = Name;
      this.Number = Number;
      this.Shift_ID = Shift_ID;
      this.SoftShift = SoftShift;
      this.StartTime = StartTime;
    }
  
    static fromJson(data: any): ShiftsItem[] {
      const shiftsArray: ShiftsItem[] = [];
      data.forEach(item => {
        shiftsArray.push(new ShiftsItem(
          item.Closed,
          item.ndTIme,
          item.Name,
          item.Number,
          item.Shift_ID,
          item.SoftShift,
          item.StartTime

        )
        );
      });
      return shiftsArray;
    }
  }
  