import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {NavigationService} from '../../services/navigation.service';
import {NavigationItem} from '../../model/navigation-item.model';
import {CustomerDataService} from 'src/app/modules/shared/customer/customer-data.service';
import {of, Subject} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  shareReplay,
  takeUntil
} from 'rxjs/operators';
import {UserPreferencesService} from 'src/app/modules/shared/preferences/user-preferences.service';
import {Sidebar} from 'primeng/primeng';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import * as $ from 'jquery';
import { RouterExtService } from '../../services/router-ext-service';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() changeVisibility: void;
  @Output() sideBarClosed = new EventEmitter<any>();
  @ViewChild('sidebar') sidebar: Sidebar;

  public visible = false;
  public activeElement: NavigationItem;

  public title: string;
  public sideNavigationItems: NavigationItem[] = [];
  private homeItems: NavigationItem[] = [];
  private $destroy = new Subject();

  constructor(
    private navService: NavigationService,
    private customerDataService: CustomerDataService,
    private userPreferencesService: UserPreferencesService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterExtService
  ) {
  }

  ngOnInit() {
    this.sideNavigationItems = this.homeItems;
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.selectCorrectSidebarItem(event);
      });

    this.navService
      .getNavigationData()
      .pipe(takeUntil(this.$destroy))
      .subscribe((data: NavigationItem[]) => {

        this.homeItems = data;
        this.sideNavigationItems = this.homeItems;
        this.title = this.translateService
          .instant(this.sideNavigationItems[0].label)
          .toLowerCase();
        this.buildMenu();
      });

    this.userPreferencesService
      .getManuStructureObject()
      .pipe(takeUntil(this.$destroy), distinctUntilChanged(), shareReplay())
      .subscribe(data => {
        this.buildMenu();
      });
  }

  private selectCorrectSidebarItem(event) {
    if (event.url.includes('setup/configure')) {
      const element = this.sideNavigationItems.find(eleme => eleme.id === 'setup');
      if (element) {
        for (const child of element.children) {
          element.active = true;
          this.activeElement = element;
          child.active = true;
        }
      }
      return;
    }

    for (const el of this.sideNavigationItems) {
      el.active = false;
      for (const child of el.children) {
        child.active = false;
        if (event.url === child.route) {
          this.activeElement = el;
          el.active = true;
          child.active = true;
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['changeVisibility']) {
      this.visible = changes['changeVisibility'].currentValue;
    }
  }

  buildMenu() {
    const menuObject = this.userPreferencesService.getMenuStructure();

    if (menuObject && this.homeItems && this.homeItems.length > 0) {
      this.sideNavigationItems = [];
      this.homeItems.map(menuItem => {
        const serverMenuObject = menuObject.find(
          obj => obj.Code === menuItem.id
        );
        const serverMenuChildren = [];
        if (serverMenuObject) {
          menuItem.children.map(child => {
            const serverMenuPage = serverMenuObject.Pages.find(
              obj => obj.Code === child.id
            );

            // #5987 - hide distribution rules by hardcoding
            if (serverMenuPage && serverMenuPage.Code !== 'distributionrules') {
              serverMenuChildren.push({
                ...child,
                label: serverMenuPage.Title
              });
            }
          });

          if (this.activeElement && this.activeElement.id === menuItem.id) {
            menuItem.active = true;
          }

          this.sideNavigationItems.push({
            ...menuItem,
            label: serverMenuObject.Title,
            children: serverMenuChildren
          });
        } else {

          if (!menuObject || menuObject.length == 0) {
            this.sideNavigationItems.push({
              ...menuItem,
              label: menuItem.label
            });
          }

        }
      });

      var route = this.router.url;
      route = route === "/" ? "/dashboard/daily-status" : route;

      // Fix non-accessible routes.
      this.navService.getNavigationData().subscribe(items => {

        for(var i = 0; i < items.length; i++){
          var item = items[i];
          var current = item.children.filter(x => { return x.route === route});

          if(current.length > 0){

            var currentNavigationItem = current[0];
            var menuItem = menuObject.filter(x => { return x.Code == item.id})[0];

            if(menuItem){
              var canAccessPage = menuItem.Pages.filter(x => { return x.Code == currentNavigationItem.id}).length > 0;
              if(!canAccessPage){

                var accessiblePageCode = menuItem.Pages[0].Code;
                var accessiblePageRoutes = items.filter(x => { return x.children.filter(y => y.id == accessiblePageCode).length > 0})[0];
                if(accessiblePageRoutes && accessiblePageRoutes.children.length > 0){
                  var aroute = accessiblePageRoutes.children.filter(x => { return x.id == accessiblePageCode})[0];
                  if(aroute){
                    this.router.navigate([aroute.route])
                  }
                }
              }
            }
          }
        }
      });

      // WA fix for mess in MENU after first login attempt.
      var urlReferer = this.routerService.getPreviousUrl();
      if(urlReferer)
      {
        if(urlReferer.indexOf("login") > -1){
          $(".left-icons-wrapper").find("span").last().click();
          $(".left-icons-wrapper").find("span").first().click();
        }
      }
    }

  }

  onClick(elementIndex: number) {
    if(this.sideNavigationItems[elementIndex]){
      if (!this.sideNavigationItems[elementIndex].active) {
        for (const el of this.sideNavigationItems) {
          el.active = false;
        }
      }

      this.activeElement = this.sideNavigationItems[elementIndex];
      this.sideNavigationItems[elementIndex].active = true;
      this.title = this.translateService
        .instant(this.activeElement.label)
        .toLowerCase();
    }

  }

  onNavChildClick(elementIndex: number) {
    for (const el of this.sideNavigationItems) {
      for (const child of this.activeElement.children) {
        child.active = false;
      }
    }

    this.activeElement.children[elementIndex].active = true;
  }

  onHide() {
    this.visible = false;
    this.sideBarClosed.emit(false);
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
