export class TimeCard {
    Breaks: [];
    Shifts: [];
    TimeCards: []

    constructor(json) {
        Object.assign(this, json);
        if (json.TimeCards && json.TimeCards.length) {
          this.TimeCards = json.TimeCards;
        }
      } 
}