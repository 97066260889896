import * as moment from 'moment';
const formatTime = time => moment.utc(time * 1000).format('mm:ss');
export class CustomerTimeTab {
  employee_id: string;
  time_emplname: any;
  time_exportid: Number;
  time_ovthours: any;
  time_ovtpay: any;
  time_reghours: any;
  time_regpay: any;
  time_totalbreaks: any;
  time_totalhours: any;
  time_totalpay: any;

    constructor(
        employee_id:string,
        time_emplname,
        time_exportid:Number,
        time_ovthours,
        time_ovtpay,
        time_reghours,
        time_regpay,
        time_totalbreaks,
        time_totalhours,
        time_totalpay,
    ) {
        this.employee_id = employee_id;
        this.time_emplname = time_emplname,
        this.time_exportid = Number(time_exportid),
        this.time_ovthours = formatTime(time_ovthours)
        this.time_ovtpay = Number(time_ovtpay);
        this.time_reghours = formatTime(time_reghours);
        this.time_regpay = Number(time_regpay);
        this.time_totalbreaks = formatTime(time_totalbreaks);
        this.time_totalhours = formatTime(time_totalhours);
        this.time_totalpay = Number(time_totalpay);
    }
    static fromJson(data: any): CustomerTimeTab[] {
        const customerTimeTab: CustomerTimeTab[] = [];
        data.forEach(time => {
            customerTimeTab.push(
                new CustomerTimeTab(
                    time.employee_id,
                    time.time_emplname,
                    time.time_exportid,
                    time.time_ovthours,
                    time.time_ovtpay,
                    time.time_reghours,
                    time.time_regpay,
                    time.time_totalbreaks,
                    time.time_totalhours,
                    time.time_totalpay,
                )
            );
        });
        return customerTimeTab;
    }
}
