import {
  Component,
  Input,
  OnInit,
  Inject,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef, Renderer2
} from '@angular/core';
import {AuthService} from '../../../auth/services/auth.service';
import {CdkDragDrop, copyArrayItem} from '@angular/cdk/drag-drop';
import {NavigationItem} from '../../model/navigation-item.model';
import {NavigationService} from '../../services/navigation.service';
import {Customer} from '../../../auth/model/customer.model';
import {CustomerDataService} from '../../../shared/customer/customer-data.service';
import {UserPreferencesService} from '../../../shared/preferences/user-preferences.service';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MenuItem} from 'primeng/api';
import {catchError, takeUntil, share, first, skip} from 'rxjs/operators';
import {of, Subscription, Subject} from 'rxjs';
import * as HttpStatus from 'http-status-codes';
import {CustomerItem} from '../../../shared/customer/model/customer.model';
import {CalendarService} from '../../../shared/calendar/services/calendar.service';
import * as moment from 'moment';
import {DailyStatusService} from 'src/app/modules/dashboard/services/daily-status.service';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
import {PageMenuService} from '../../services/page-menu.service';
import {Listbox} from 'primeng/primeng';

@Component({
  selector: 'app-gratuity-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('locationListBox') locationListBox: Listbox;
  @ViewChild('mainNavigation') mainNavigation: ElementRef;

  @Input() breadCrumbLinks: MenuItem[];
  @Input() showMenuButton: boolean;
  @Input() hideHeader: boolean;

  @Output() changeCustomer: EventEmitter<any> = new EventEmitter();
  @Output() openSidebarMenu = new EventEmitter<void>();

  public showConfirmLogoutPopUp = false;
  public showFullscreen: boolean;
  public showMenuBackground: boolean;
  elem;
  currentUser: string;
  defaultCustomer: Customer;
  customersForm: FormGroup;
  sideNavigationItems: NavigationItem[] = [];
  headerNavigationItems: NavigationItem[] = [];
  customerLocations: Customer[] = [];
  showLocationWindow = false;
  isSearchLocationShowing = false;
  public selectedLocation;
  public menuEnabled;
  public selectedLocationName;
  $selectedLocationSubscription: Subscription;
  private $destroy = new Subject();
  public filterLocationsText;
  private isMenuCallBack = false;
  get serviceStatusMessage() {
    return localStorage.getItem("serviceStatusMessage");
  }
  showServiceStatusPopUp = false;

  constructor(
    private authService: AuthService,
    private navService: NavigationService,
    @Inject(DOCUMENT) private document: any,
    private customerDataService: CustomerDataService,
    private preferencesService: UserPreferencesService,
    private router: Router,
    private translateService: TranslateService,
    private calendarService: CalendarService,
    private dailyStatusService: DailyStatusService,
    private pageMenuService: PageMenuService
  ) {
    this.preferencesService
      .getCustomerLocations()
      .subscribe((customers: Customer[]) => {
        if (customers && customers.length) {
          this.defaultCustomer = customers[0];
        }
      });

    this.customersForm = new FormGroup({
      // Try to set value of ng prime dropdown
      selectedCustomer: new FormControl(this.defaultCustomer)
    });
  }

  ngOnInit() {
    this.isMenuCallBack = false;
    this.showMenuBackground = false;
    this.showFullscreen = false;
    this.menuEnabled = true;
    this.elem = document.documentElement;
    this.preferencesService.getNavBookmarks().subscribe(data => {
      if (data) {
        data.forEach(item => {
          this.headerNavigationItems.push(new NavigationItem(item, item));
        });
      }
    });

    this.authService.authContextObservable
      .pipe(takeUntil(this.$destroy))
      .subscribe(authContext => {
        if (authContext != null) {
          this.currentUser = authContext.Name;
          this.customerLocations = [...authContext.Customers];
        }
      });

    this.navService
      .getNavigationData()
      .pipe(takeUntil(this.$destroy))
      .subscribe((data: NavigationItem[]) => {
        this.sideNavigationItems = data;
      });

    this.$selectedLocationSubscription = this.customerDataService
      .getData()
      .pipe(takeUntil(this.$destroy))
      .subscribe(data => {
        localStorage.setItem('isPageCallBack', "false");
        if (data) {
          this.selectedLocation = data;
          this.selectedLocationName = this.selectedLocation[0].Name;
          this.customersForm = new FormGroup({
            selectedCustomer: new FormControl(data[0])
          });
          if(!this.isMenuCallBack){
            this.getMenuObject(data[0]);
            localStorage.setItem('isPageCallBack', "true");
          }
          
        } else {
          const dataFromLocalStorage = JSON.parse(
            localStorage.getItem('customers')
          );
          if (dataFromLocalStorage) {
            this.selectedLocation = dataFromLocalStorage;
            this.selectedLocationName = dataFromLocalStorage[0].Name;
            this.customersForm = new FormGroup({
              selectedCustomer: new FormControl(dataFromLocalStorage[0])
            });
            if (this.preferencesService.getMenuStructure() === undefined) {
              this.getMenuObject(dataFromLocalStorage[0]);
            }
          } else {
            this.menuEnabled = false;
          }
        }
      });
      
  }

  get canGlobalSearch(): boolean {
    return this.authService.canGlobalSearch;
  }

  isGlobalSearchVisible(){
    return this.canGlobalSearch;
  }
  
  tuggleSidebarMenu() {
    this.openSidebarMenu.emit();
  }

  openSelectLocationbWindow() {
    this.showLocationWindow = true;
    setTimeout(() => {
      this.locationListBox.el.nativeElement
        .getElementsByTagName('input')[1]
        .focus();
    }, 500);
  }

  closeSelectLocationbWindow() {
    this.showLocationWindow = false;
    this.locationListBox.el.nativeElement.getElementsByTagName(
      'input'
    )[1].value = '';
    this.locationListBox.el.nativeElement
      .getElementsByTagName('input')[1]
      .dispatchEvent(new Event('input'));
  }

  getMenuObject(selectedCustomer: Customer) {
    this.menuEnabled = false;
    this.pageMenuService
      .getMenu()
      .pipe(
        catchError(response => {
          if (response.status === HttpStatus.BAD_REQUEST) {
          }
          return of(response);
        }),
        takeUntil(this.$destroy)
      )
      .subscribe(response => {
        if (response && response.status && response.status !== HttpStatus.OK) {
          this.menuEnabled = true;
          return;
        }
        if (response && response.length > 0) {
          this.preferencesService.setMenuStructure(response);
          this.getPageObject(selectedCustomer);
        } else {
          this.menuEnabled = true;
        }
      });
  }

  getPageObject(selectedCustomer: Customer) {
    this.pageMenuService
      .getPage()
      .pipe(
        catchError(response => {
          if (response.status === HttpStatus.BAD_REQUEST) {
          }
          return of(response);
        }),
        takeUntil(this.$destroy)
      )
      .subscribe(response => {
        this.preferencesService.setPageStructure(response);
        this.menuEnabled = true;
      });
  }

  logout() {
    this.showConfirmLogoutPopUp = true;    
  }

  logoutConfirm() {
    this.customerDataService.setData(null);
    this.authService.logout();
    this.isMenuCallBack = false;
    this.showConfirmLogoutPopUp = false;
  }

  cancelLogoutDialog() {
    this.showConfirmLogoutPopUp = false;
  }

  drop(event: CdkDragDrop<any[]>) {
    if (this.isDragAndDropAllowed(event)) {
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.preferencesService.setNavBookmarks(
        this.headerNavigationItems.map(item =>
          this.translateService.instant(item.label)
        )
      );
    }
  }

  removeItem(item) {
    this.headerNavigationItems = this.headerNavigationItems.filter(
      headerItem => headerItem !== item
    );
    this.preferencesService.setNavBookmarks(
      this.headerNavigationItems.map(it =>
        this.translateService.instant(it.label)
      )
    );
  }

  onCustomerChange(value) {
    this.isMenuCallBack =true;
    localStorage.setItem('isLocationChange',"true");
    Array.isArray(value)
      ? this.customerDataService.emitData(value, () => {
        this.getMenuObject(value[0]);
      })
      : this.customerDataService.emitData([value], () => {
        this.getMenuObject(value);
      });

    this.filterLocationsText = "";
    this.isSearchLocationShowing = false;
    this.changeCustomer.emit(value);
  }

  private isDragAndDropAllowed(event) {
    return (
      this.isDraggedFromSideNav(event) &&
      this.notDraggingToSameContainer(event) &&
      this.numberOfElementsLessThanFour(event) &&
      this.elementAlreadyExists(event)
    );
  }

  private isDraggedFromSideNav(event) {
    return event.previousContainer.id === 'side_nav';
  }

  private notDraggingToSameContainer(event) {
    return event.previousContainer !== event.container;
  }

  private numberOfElementsLessThanFour(event) {
    return event.container.data.length <= 3;
  }

  private elementAlreadyExists(event) {
    return !event.container.data
      .map(element => this.translateService.instant(element.label))
      .includes(event.item.element.nativeElement.innerHTML.trim());
  }

  openFullscreen() {
    this.showFullscreen = true;
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    this.showFullscreen = false;
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  goToUserDetails() {
    this.router.navigate(['control_panel/change-details']);
  }

  closeMenu() {
    this.showMenuBackground = false;
  }

  openMenu() {
    this.showMenuBackground = !this.showMenuBackground;
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  
}
