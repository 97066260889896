import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss']
})
export class MainContainerComponent implements OnInit {
  @Input() breadCrumbLinks = [];
  @Output() changeCustomer: EventEmitter<any> = new EventEmitter();
  public visibleMenu = false;
  constructor() {}

  ngOnInit() {}

  sideBarClosed(event) {
    this.visibleMenu = event;
  }

  onOpenSidebarMenu() {
    this.visibleMenu = !this.visibleMenu;
  }
  onChangeCustomer(event) {
    this.changeCustomer.emit(event);
  }
}
