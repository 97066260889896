import { Customer } from './customer.model';
import { AuthContextDto } from './auth-context.dto';

export class AuthContext implements AuthContextDto {
  ChangePassUponLogin: boolean;
  ChooseOneCustomer: boolean;
  ShowUserAgreement: boolean;
  Location: string;
  Message: string;
  Name: string;
  Role: string;
  Success: boolean;
  Token: string;
  UserCode: number;
  Customers: Customer[];


  constructor(json) {
    Object.assign(this, json);

    if (json.Customers && json.Customers.length) {
      this.Customers = [];
      json.Customers.forEach((item) => {
        this.Customers.push(new Customer(item));
      });
    }
  }
}
