import { MenuItem } from 'primeng/api';
export interface EmployeeMapping {
  pageObject: {};
  employeeMappingData;
  employeeMappingTimeMappings;
  employeeMappingPayRollWorkers;
  employeeMappingTimeWorkers;
  employeeMappingPayRollDataColumns;
  employeeMappingTimeDataFromApi;
  employeeMappingTimeDataColumns;
  filteredEmployeeTimeMapping: [];
  cleanEmployeeTimeMapping: [];
  filter: boolean;
  changedData;
  isLoading: boolean;
}
export class EmployeeMappingState implements EmployeeMapping {
  pageObject: {} =
    localStorage.getItem('page') != null
      ? JSON.parse(localStorage.getItem('page'))
      : {};
  employeeMappingData = [];
  employeeMappingTimeMappings = [];
  employeeMappingPayRollWorkers = [];
  employeeMappingTimeWorkers = [];
  employeeMappingPayRollDataColumns = [
    { field: 'PosEmployeeName', translationLabel: 'POS Employee' },
    { field: 'PayrollEmployee', translationLabel: 'Payroll Employee' },
    { field: 'PayrollIgnore', translationLabel: 'Ignore' }
  ];
  employeeMappingTimeDataFromApi: {};
  employeeMappingTimeDataColumns = [
    { field: 'PosEmployeeName', translationLabel: 'POS Employee' },
    { field: 'PayrollEmployee', translationLabel: 'Payroll Employee' }
  ];
  filteredEmployeeTimeMapping: [];
  cleanEmployeeTimeMapping: [];
  changedData = [];
  isLoading = false;
  filter = false;
}
