import {NavigationChild} from './navigation-child.model';

export class NavigationItem {
  id: string;
  label: string;
  isExpandable = false;
  children: NavigationChild[];
  icon: string;
  active ? = false;

  constructor(id: string, label: string, isExpandable?: boolean, children?: NavigationChild[], icon?: string) {
    this.id = id;
    this.label = label;
    if (isExpandable !== undefined) {
      this.isExpandable = isExpandable;
    }
    if (children && children.length) {
      this.children = [];
      children.forEach((item) => {
        this.children.push(new NavigationChild(item.id, item.label, item.icon, item.route));
      });
    }
    if (icon !== undefined) {
      this.icon = icon;
    }
  }

  static fromJson(data: any): NavigationItem[] {
    const navigationArray: NavigationItem[] = [];
    data.forEach(item => {
      navigationArray.push(new NavigationItem(item.id, item.label, item.isExpandable, item.children, item.icon));
    });

    return navigationArray;
  }
}
