import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { AuthService } from '../../modules/auth/services/auth.service';
import { TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { of } from 'rxjs';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  isLoading: boolean;
  isForgotPasswordValid: boolean = true;

  forgotPasswordForm: FormGroup = new FormGroup({
    emailOrPhone: new FormControl('', Validators.required),
    username: new FormControl('', Validators.required)
  });

  constructor(private authService: AuthService, private router: Router, private translationService: TranslateService) { }

  ngOnInit() {
  }

  cancelForgotPassword() {
    this.router.navigate(['login']);
  }

  onNext(event){
    const emailOrPhone = this.forgotPasswordForm.get('emailOrPhone').value;
    const username = this.forgotPasswordForm.get('username').value;

    this.isLoading = true;
    this.isForgotPasswordValid = true;

    this.authService.forgotPassword(emailOrPhone, username)
      .pipe(catchError((response) => {
          if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.NOT_FOUND) {
            this.isForgotPasswordValid = false;
          }
          this.isLoading = false;
          return of(response);
        }
      ))
      .subscribe((response) => {
        if (this.isForgotPasswordValid) {
          this.isLoading = false;
          this.router.navigate(['reset-password']);
        }
      });
  }

}
