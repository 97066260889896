import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../modules/auth/services/auth.service';
import { TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { of } from 'rxjs';
import { invalid } from '@angular/compiler/src/render3/view/util';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  
  isLoading: boolean;
  isResetPasswordValid: boolean = true;
  message_passwords_doesent_match: string;
  message_reset_code_invalid: string;
  errorMessage: string;
  
  resetPasswordForm: FormGroup = new FormGroup({
    resetCode: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    repeatedPassword: new FormControl('', Validators.required)
  });

  constructor(private authService: AuthService, private router: Router, private translationService: TranslateService) {
    this.message_passwords_doesent_match = this.translationService.instant('APP.RESET_PASSWORD.PASSWORDS_DOESNT_MATCH');
    this.message_reset_code_invalid = this.translationService.instant('APP.RESET_PASSWORD.MESSAGE_RESET_CODE_INVALID');
    this.errorMessage = this.message_passwords_doesent_match
   }

  ngOnInit() {
    this.isResetPasswordValid = true;
  }

  passwordIsCorrectlyRepeated(password, repeatedPassword){
    if(password === repeatedPassword){
      return true;
    }
    return false;
  }

  cancelResetPassword() {
    this.router.navigate(['login']);
  }

  resetPassword(event){
    const resetCode = this.resetPasswordForm.get('resetCode').value;
    const password = this.resetPasswordForm.get('password').value;
    const repeatedPassword = this.resetPasswordForm.get('repeatedPassword').value;
    
    this.isLoading = true;
    this.isResetPasswordValid = true;

    if(!this.passwordIsCorrectlyRepeated(password, repeatedPassword)){
      this.errorMessage = this.message_passwords_doesent_match
      this.isResetPasswordValid = false;
      this.isLoading = false;
    }else{
      this.isLoading = true;
      this.authService.resetPassword(resetCode, password)
        .pipe(catchError((response) => {
            if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.NOT_FOUND) {
              this.errorMessage = this.message_reset_code_invalid
              this.isResetPasswordValid = false;
            }
            this.isLoading = false;
            return of(response);
          }
        ))
        .subscribe((response) => {
          if (this.isResetPasswordValid) {
            this.isLoading = false;
            this.router.navigate(['login']);
          }
        });
    }
  }

}
