import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Customer} from '../../auth/model/customer.model';
import {UserPreferencesService} from '../preferences/user-preferences.service';
import { CustomerItem } from './model/customer.model';
import { ConfigService } from '../config/services/config.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerDataService {

  public static RESOURCE_SERVICE = 'ResourceService';
  public static ADMIN_SERVICE = 'AdminService';
  private customerContextSubject: BehaviorSubject<Customer[]> = new BehaviorSubject<Customer[]>(null);

  constructor(private userPreferenceService: UserPreferencesService, private http: HttpClient, private configService: ConfigService) {
  }

  public emitData(data: Customer[], callback) {
    this.userPreferenceService.setCustomerLocations(data);
    this.customerContextSubject.next(data);
    callback();
  }

  public getData(): Observable<Customer[]> {
    return this.customerContextSubject.asObservable();
  }

  public setData(data){
    return this.customerContextSubject.next(data);
  }
}
