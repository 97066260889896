import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import {AnalyticsService} from '../../shared/analytics-service.service';
import { catchError } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { of, Subscription } from 'rxjs';
import { AnalyticsItem } from './model/analytics-item.model';
import { TranslateService } from '@ngx-translate/core';
import { CustomerDataService } from '../customer/customer-data.service';
import { Customer } from '../../auth/model/customer.model';

@Component({
  selector: 'app-analytics-report',
  templateUrl: './analytics-report.component.html',
  styleUrls: ['./analytics-report.component.scss']
})
export class AnalyticsReportComponent implements OnInit, OnDestroy {

  isLoading = false;
  public maxDate:Date;
  public minDate:Date;
  public miniSeparator = '\xa0';
  public dateFrom:Date;
  public dateTo:Date;
  private todayDate = moment().toDate();
  public analyticsData:AnalyticsItem[];
  public isAnalyticsDataEmpty : boolean = true;
  public headerTitle:string;
  public showAnalyticsAccordion:boolean;
  private $customerDataServiceSubcription: Subscription;

  constructor(private analyticsService: AnalyticsService, private translationService:TranslateService,
    private customerDataService: CustomerDataService) {
    this.showAnalyticsAccordion = false;
   }

  ngOnInit() {
    this.$customerDataServiceSubcription = this.customerDataService
      .getData()
      .subscribe((data: Customer[]) => {
        if (data && data.length) {
          this.showAnalyticsAccordion = false;
          this.isAnalyticsDataEmpty = true;
          this.analyticsData = [];
        }
      });
  }

  ngOnDestroy(){
    if(this.$customerDataServiceSubcription) this.$customerDataServiceSubcription.unsubscribe();
  }

  convertDateToWebApiDateFormat(date:Date){
    return moment(date).format('YYYY-MM-DD');
  }

  onSelectDate(event){
    this.minDate = this.dateFrom;
  }

  onLoad(){
    const dateFrom = this.convertDateToWebApiDateFormat(this.dateFrom);
    const dateTo = this.convertDateToWebApiDateFormat(this.dateTo);
    const totalsStats = this.translationService.instant('APP.ANALYTICS_REPORT.TOTALS_AND_STATS');
    const to = this.translationService.instant('APP.ANALYTICS_REPORT.TO');
    this.headerTitle = totalsStats + this.miniSeparator + this.miniSeparator + dateFrom + to + dateTo;
    this.isLoading = true;
    this.analyticsService.getAnalyticsReportData(dateFrom, dateTo)
      .pipe(catchError((response) => {
          if (response.status === HttpStatus.BAD_REQUEST) {
            console.log("Bad request");
          }
          this.isLoading = false;
          return of(response);
        }
      ))
      .subscribe((response) => {
        if (response) {
          this.isLoading = false;
          this.showAnalyticsAccordion = true;
          const analyticsData = response;
          this.analyticsData = analyticsData.map(analyticsItem => {
            const translatedName = this.translationService.instant('APP.ANALYTICS_REPORT.' + analyticsItem.code.toUpperCase());
            const translatedAnalyticsData = {'code': translatedName, 'per': analyticsItem.per, 'total': analyticsItem.total}
            return translatedAnalyticsData;
          })
          this.isAnalyticsDataEmpty = this.analyticsData.length === 0;
        }
      });
  }


}
