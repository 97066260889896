import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { PrintService } from '../print.service';

@Component({
    selector: 'app-print',
    templateUrl: './print.component.html',
    styleUrls: ['./print.component.scss'],
})
export class PrintComponent implements OnInit {
    public tableContent;


    constructor( private location: Location) {}

    ngOnInit() {
        this.tableContent = JSON.parse(localStorage.getItem(PrintService.LOCALSTORAGE_ID));

    }

    printContent(): void {
        window.print();
    }

    closePrint(): void {
        window.close();
        //this.location.back();
    }
}
