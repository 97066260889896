import { NgModule } from '@angular/core';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ColumnChooserComponent } from './customizable-table/column-chooser/column-chooser.component';
import {
  AccordionModule,
  DialogModule,
  MultiSelectModule,
  ProgressSpinnerModule,
  GrowlModule,
  DropdownModule,
  PaginatorModule
} from 'primeng/primeng';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ErrorDialogComponent } from './error/error-dialog/error-dialog.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { AnalyticsReportComponent } from './analytics-report/analytics-report.component';
import { CalendarModule } from 'primeng/calendar';
import { AnalyticsReportItemComponent } from './analytics-report-item/analytics-report-item.component';
import { ChartModule } from 'primeng/chart';
import { GridComponent } from './grid/grid.component';
import { FilteringPipe } from './pipes/filtering.pipe';
import { PopUpConfirmationComponent } from './pop-up-confirmation/pop-up-confirmation.component';
import { PopUpFormComponent } from './pop-up-form/pop-up-form.component';
import { GridExpandedComponent } from './grid-expanded/grid-expanded.component';
import { CustomPieComponent } from './custom-pie/custom-pie.component';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { InnerSpinnerComponent } from './inner-spinner/inner-spinner.component';
import { SuccessDialogComponent } from './success-dialog-modal/success-dialog.component';
import { MappingGridComponent } from './mapping-grid/mapping-grid.component';
import { PrintComponent } from './print/print.component';
import { DigitOnlyDirective } from './directives/digit-only.directive';
import {DisableControlDirective } from './directives/disableControl.directive'
import { InputAddonDirective } from './directives/input-addon.directive';
import { from } from 'rxjs';
import { PhoneMaskDirective } from './phone-mask.directive';

@NgModule({
  declarations: [
    LoadingSpinnerComponent,
    CalendarComponent,
    ColumnChooserComponent,
    ErrorDialogComponent,
    AnalyticsReportComponent,
    AnalyticsReportItemComponent,
    GridComponent,
    FilteringPipe,
    PopUpConfirmationComponent,
    PopUpFormComponent,
    GridExpandedComponent,
    CustomPieComponent,
    ModalDialogComponent,
    InnerSpinnerComponent,
    SuccessDialogComponent,
    MappingGridComponent,
    PrintComponent,
    DigitOnlyDirective,
    DisableControlDirective,
    InputAddonDirective,
    PhoneMaskDirective
  ],
  entryComponents: [ErrorDialogComponent],
  providers: [],
    exports: [
        LoadingSpinnerComponent,
        CalendarComponent,
        ColumnChooserComponent,
        AnalyticsReportComponent,
        GridComponent,
        GridExpandedComponent,
        AnalyticsReportItemComponent,
        FilteringPipe,
        PopUpConfirmationComponent,
        PopUpFormComponent,
        ModalDialogComponent,
        InnerSpinnerComponent,
        SuccessDialogComponent,
        MappingGridComponent,
        PrintComponent,
        DigitOnlyDirective,
        InputAddonDirective,
        PhoneMaskDirective
    ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
    DialogModule,
    DynamicDialogModule,
    TabMenuModule,
    MenuModule,
    ButtonModule,
    RadioButtonModule,
    CalendarModule,
    AccordionModule,
    ProgressSpinnerModule,
    ChartModule,
    GrowlModule,
    DropdownModule,
    PaginatorModule
  ]
})
export class SharedModule {}
