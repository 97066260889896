import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged, shareReplay, tap } from 'rxjs/operators';
import { MenuObject } from '../model/menu-object.model';
import { PageObject } from '../model/page-object.model';
import { UserPreferencesService } from '../../shared/preferences/user-preferences.service';
import { ConfigService } from '../../shared/config/services/config.service';
import { MenuObjectDto } from '../model/menu-object.dto';
import { PageObjectDto } from '../model/page-object.dto';

@Injectable({
  providedIn: 'root'
})
export class PageMenuService {
  public static ADMIN_SERVICE = 'AdminService';
  private dailyStatusTabs = new BehaviorSubject([]);
  constructor(
    private http: HttpClient,
    private userPreferencesService: UserPreferencesService,
    private configService: ConfigService
  ) {}

  getMenu(): Observable<MenuObjectDto[]> {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${
      PageMenuService.ADMIN_SERVICE
    }/api/Menu?&customerCode=${customerCode}`;
    return this.http
      .get(address)
      .pipe(map(jsonData => MenuObject.fromJson(jsonData)));
  }

  getPage(): Observable<PageObjectDto> {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${
      PageMenuService.ADMIN_SERVICE
    }/api/Page?&customerCode=${customerCode}`;
    return this.http.get(address).pipe(
      shareReplay(1),
      distinctUntilChanged(),
      map(jsonData => PageObject.fromJson(jsonData)),
      tap(pageObject =>
        this.dailyStatusTabs.next(pageObject['dash_dailyStatus']['Tabs'])
      )
    );
  }

  get $dailyStatusTabs() {
    return this.dailyStatusTabs.asObservable();
  }
}
