import {NgModule} from '@angular/core';
import {HeaderComponent} from './components/header/header.component';
import {SidebarMenuComponent} from './components/sidebar-menu/sidebar-menu.component';
import {MainContainerComponent} from './components/main-container/main-container.component';
import {NavigationService} from './services/navigation.service';
import {AuthModule} from '../auth/auth.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MenuModule} from 'primeng/menu';
import {SharedModule as PrimeNgSharedModule} from 'primeng/shared';
import {SharedModule} from '../../modules/shared/shared.module';
import {
  BreadcrumbModule,
  ButtonModule,
  DropdownModule,
  InputTextModule,
  ListboxModule,
  MultiSelectModule,
  PanelMenuModule,
  ProgressSpinnerModule,
  SidebarModule
} from 'primeng/primeng';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import { RouterExtService } from './services/router-ext-service';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarMenuComponent,
    MainContainerComponent
  ],
  providers: [NavigationService, RouterExtService],
  exports: [
    HeaderComponent,
    SidebarMenuComponent,
    MainContainerComponent
  ],
  imports: [
    AuthModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MenuModule,
    PrimeNgSharedModule,
    SharedModule,
    ProgressSpinnerModule,
    InputTextModule,
    PanelMenuModule,
    SidebarModule,
    ListboxModule,
    DragDropModule,
    DropdownModule,
    MultiSelectModule,
    ButtonModule,
    TranslateModule,
    BreadcrumbModule
  ]
})
export class NavigationModule {}
