import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ExportExcelService } from './services/export-excel.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class GridComponent implements OnInit {

  @Input() data: {}[];
  @Input() columns: {}[];
  @Input() showDeleteButton = false;
  @Input() showEditButton = false;
  @Input() showExpandButton = false;
  @Input() showTeamUpButton = false;

  public pagination: Boolean = true;
  public selectedColumns: any[];
  public totalEntities: number;
  public tableRows = 7;
  public showDataFrom: number;
  public showDataTo: number;
  private selectedRowIndex: Number;

  constructor(private excelService: ExportExcelService) { }

  ngOnInit() {
    this.totalEntities = this.data.length;
    this.showDataFrom = 1;
    this.showDataTo = this.tableRows;
    this.selectedColumns = this.columns;
    if(this.showDataTo > this.totalEntities){
      this.showDataTo = this.totalEntities;

    }
  }

  exportToExcel() {
    const dataToExport = [...this.data];
    this.excelService.exportAsExcelFile(dataToExport, 'export from grid');
  }
  paginate(event) {
    const { first } = event;
    this.showDataFrom = first + 1;
    this.showDataTo = (this.showDataFrom - 1) + this.tableRows;
    if(this.showDataTo > this.totalEntities){
      this.showDataTo = this.totalEntities;
    }
  }

  teamUp(rowData){
  }

  delete(rowData){
    // this.showDeleteConfirmation = true;
    // this.dashboardService.showDeleteConfirmation.next(true);
    // this.deleteTimeCards(rowData);
  }

  edit(rowData){
    // this.showDeleteConfirmation = true;
    // this.dashboardService.showDeleteConfirmation.next(true);
    // this.deleteTimeCards(rowData);
  }
}
