export class CustomerAddress {
  City: string;
  CustomerId: number;
  Id: number;
  Latitude: number;
  Longitude: number;
  State: string;
  SateCode: string;
  Street: string;
  StreetNumber: string;
  Zip: string;

  constructor(json) {
    Object.assign(this, json);
  }
}
