import {Moment} from 'moment';
import * as moment from 'moment';

export class CustomerConnector {
  public CustomerCode: number;
  public ConnectorId: number;
  public ConnectorName: string;
  public DateInserted: Moment;

  constructor(json) {
    Object.assign(this, json);

    if (json.DateInserted) {
      this.DateInserted = moment(json.DateInserted);
    }
  }
}
