import {
  Component,
  OnInit,
  ElementRef,
  AfterContentInit,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../modules/auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, takeUntil } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { of, Subject } from 'rxjs';
import { CustomerDataService } from 'src/app/modules/shared/customer/customer-data.service';
import { ErrorService } from 'src/app/modules/shared/error/services/error.service';
import { ServiceStatusMessageService } from '../../modules/control-panel/services/service-status.message.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild('userName') userName: ElementRef;
  isLoading = false;
  isLoginValid = true;
  private captchaCounter = 0;
  public isCaptchaVisible = false;
  private maxLoginFailureAttempts = 3;
  private customerData;
  private $destroy = new Subject();
  loginForm: FormGroup = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private translationService: TranslateService,
    private elementRef: ElementRef,
    private errorService: ErrorService,
    private customerDataService: CustomerDataService,
    private messagesService: ServiceStatusMessageService,
  ) {}

  ngOnInit() {
    this.customerDataService
      .getData()
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: data => {
          this.customerData = data;
        }
      });
  }
  ngAfterContentInit() {
    this.userName.nativeElement.focus();
  }
  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }
  captchaIsAttemptedMoreThanMaxAllowedFailures() {
    if (this.captchaCounter >= this.maxLoginFailureAttempts) {
      return true;
    }
    return false;
  }

  onLogin() {
    localStorage.clear();
    const username = this.loginForm.get('username').value;
    const password = this.loginForm.get('password').value;
    this.isLoading = true;
    this.getToken(username, password);
  }

  getToken(username: string, password: string) {
    this.authService
      .getToken(username, password)
      .pipe(
        catchError(response => {
          if (response.status === HttpStatus.BAD_REQUEST) {
            this.isLoginValid = false;
            this.captchaCounter++;
          }
          if (this.captchaIsAttemptedMoreThanMaxAllowedFailures()) {
            this.isCaptchaVisible = true;
          }
          this.isLoading = false;
          return of(response);
        })
      )
      .subscribe(response => {
        if (response.Success) {
          this.getCustomers();
          this.getServiceStatusMessage();
        }
      });
  }

  getCustomers() {
    this.authService
      .GetCustomers()
      .pipe(
        catchError(response => {
          if (response.status === HttpStatus.BAD_REQUEST) {
            this.isLoginValid = false;
            this.captchaCounter++;
          }
          if (this.captchaIsAttemptedMoreThanMaxAllowedFailures()) {
            this.isCaptchaVisible = true;
          }
          this.isLoading = false;
          return of(response);
        })
      )
      .subscribe(response => {
        if (response.Success) {
          this.getUserDetails();
        }
      });
  }

  getUserDetails() {
    this.authService
      .getUserDetails()
      .pipe(
        catchError(response => {
          if (response.status === HttpStatus.BAD_REQUEST) {
            this.isLoginValid = false;
            this.captchaCounter++;
          }
          if (this.captchaIsAttemptedMoreThanMaxAllowedFailures()) {
            this.isCaptchaVisible = true;
          }
          this.isLoading = false;
          return of(response);
        })
      )
      .subscribe(response => {

        if (response.Success) {
          this.isLoading = false;
          if (this.customerData != null) {
            this.router.navigate(['dashboard/daily-status']);

          } else {
            this.errorService.addErrors([
              'Could not get any  location for this user'
            ]);
            this.router.navigate(['dashboard']);
          }

          this.authService.getCanUserUseGlobalSearch();         
        }
      });
  }

  forgotPassword() {
    this.router.navigate(['forgot-password']);
  }

  handleCorrectCaptcha(event) {
    if (event) {
      this.captchaCounter = 0;
      this.isCaptchaVisible = false;
    }
  }

  getServiceStatusMessage() {
    this.messagesService.getActive().subscribe((data:any) => {
      if (data) {
        localStorage.setItem("serviceStatusMessage", data.Message);
      }
    })
  }
}
