import {CustomerConnector} from './customer-connector.model';
import {CustomerAddress} from './customer-address.model';

export class Customer {
  Code: number;
  Name: string;
  Disabled: boolean;
  isActive: boolean;
  CustomerBlocked: boolean;
  BlockType: number;
  BlockedReason: string;
  CustomerConnectors: CustomerConnector[];
  CustomerAddress: CustomerAddress;

  constructor(json) {
    Object.assign(this, json);

    if (json.CustomerConnectors && json.CustomerConnectors.length) {
      this.CustomerConnectors = [];
      json.CustomerConnectors.forEach((item) => {
        this.CustomerConnectors.push(new CustomerConnector(item));
      });
    }

    if (json.CustomerAddress) {
      this.CustomerAddress = new CustomerAddress(json.CustomerAddress);
    }
  }
}
