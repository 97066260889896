import { MenuObjectDto } from './menu-object.dto';
import { MenuPageItem } from './menu-page-item.model';

export class MenuObject implements MenuObjectDto{
    ID: number;
    Code: string;
    Title: string;
    Description: string;
    Pages: MenuPageItem[];

    constructor(item) {
        this.ID = item.ID;
        this.Code = item.Code;
        this.Title = item.Title;
        this.Description = item.Description;
        this.Pages = MenuPageItem.fromJson(item.Pages)
    }

    static fromJson(data: any): MenuObject[] {
        const menuArray: MenuObject[] = [];
        data.forEach(item => {
            menuArray.push(new MenuObject(item));
        });
    
        return menuArray;
    }
}