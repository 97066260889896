import {
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from '../../modules/auth/services/auth.service';
import { catchError, takeUntil } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { of, Subject } from 'rxjs';
import { ErrorService } from 'src/app/modules/shared/error/services/error.service';
import * as moment from "moment";
import { SelectItem } from 'primeng/api';
import { EmployeeIdentifierTypes } from '../../modules/shared/enums/EmployeeIdentifierTypes';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})

export class SignupComponent implements OnInit, OnDestroy {
  public SignupForm: FormGroup;

  submitted = false;
  dateTime = new Date();
  yearRange: string;
  public selectedDate: string = moment().format("YYYY-MM-DD");
  isLoading = false;
  public showConfirmationDialog;
  public confirmationDialogMessage;
  confirmDialogTitle = " Confirmation :";
  employeeIdentifierTypes: SelectItem[];
  EmployeeIdentifierTypesEnum = EmployeeIdentifierTypes;

  constructor(
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private authService: AuthService,
    private router: Router,

  ) { }

  onSubmit() {
    this.SignupForm.root.get('EmployeeIdentifier').updateValueAndValidity();
    this.SignupForm.root.get('MobileNumber').updateValueAndValidity();
    this.SignupForm.updateValueAndValidity();
    this.isLoading = true;
    this.submitted = true;

    if (!this.SelectedEmployeeIdentifierType)
      this.SignupForm.get("EmployeeIdentifierType").setValue(1);

    if (this.SignupForm.invalid) {
      this.isLoading = false;
      return;
    }
    const Password = this.SignupForm.get("Password").value;
    const confirmPassword = this.SignupForm.get("ConfirmPassword").value;
    const Phone = this.SignupForm.get("MobileNumber").value;

    if (Password !== confirmPassword) {
      this.errorService.addErrors([
        "Password and Confirmed Password does not match"
      ]);
      this.isLoading = false;
      return;
    }
    const FirstName = this.SignupForm.get("FirstName").value;
    const LastName = this.SignupForm.get("LastName").value;
    const UserName = this.SignupForm.get("UserName").value;
    const EmployeeIdentifier = this.SelectedEmployeeIdentifierType == this.EmployeeIdentifierTypesEnum.EmployeeID ? this.SignupForm.get("EmployeeIdentifier").value : Phone;
    const EmployeeIdentifierType = this.SelectedEmployeeIdentifierType;
    const DateOfBirth = this.selectedDate;

    const account = {
      FirstName: FirstName,
      LastName: LastName,
      UserName: UserName,
      EmployeeIdentifier: EmployeeIdentifier,
      EmployeeIdentifierType: EmployeeIdentifierType,
      Password: Password,
      DateOfBirth: DateOfBirth
    };
    this.authService.RegisterAccount(account)
      .pipe(
        catchError(response => {
          if (response.status === HttpStatus.BAD_REQUEST) {
            this.isLoading = false;
          }
          return of(response);
        })
      )
      .subscribe(response => {
        if (response == null) {
          this.isLoading = false;
          this.confirmDialogTitle = "Success";
          this.showConfirmationDialog = true;
          this.confirmationDialogMessage = "Registration successful.";
        }
        this.isLoading = false;
      });
  }
  onSelectDate(date: Date) {
    this.selectedDate = moment(date).format("YYYY-MM-DD");
  }
  prepereForm() {
    this.SignupForm = new FormGroup({
      FirstName: new FormControl('', Validators.required),
      LastName: new FormControl('', Validators.required),
      UserName: new FormControl('', [Validators.required, Validators.pattern("[A-Za-z0-9_.@-]*$")]),
      EmployeeIdentifier: new FormControl('', this.validateEmployeeID),
      EmployeeIdentifierType: new FormControl('', Validators.required),
      Password: new FormControl('', Validators.required),
      ConfirmPassword: new FormControl('', Validators.required),
      MobileNumber: new FormControl('', this.validatePhone),
      //[
      //  Validators.required,
      //  Validators.maxLength(20)
      //]),
      DateOfBirth: new FormControl('', Validators.required),
    });

    this.SignupForm.get("EmployeeIdentifierType").setValue(EmployeeIdentifierTypes.EmployeeID);
  }

  get SelectedEmployeeIdentifierType() {
    if (this.SignupForm.get("EmployeeIdentifierType") && this.SignupForm.get("EmployeeIdentifierType").value)
     return this.SignupForm.get("EmployeeIdentifierType").value;
  }

  validateEmployeeID(group: FormGroup) {
    let val = null;
    if (group.root.get('EmployeeIdentifierType') && group.root.get('EmployeeIdentifierType').value == EmployeeIdentifierTypes.EmployeeID) {
      if (group.root.get('EmployeeIdentifier'))
        val = group.root.get('EmployeeIdentifier').value;

      if (val == null || val.toString().trim() == '')
        return { required: true }
    }

    return null;
  }


  validatePhone(group: FormGroup) {
    let val = null;
    if (group.root.get('EmployeeIdentifierType') && group.root.get('EmployeeIdentifierType').value == EmployeeIdentifierTypes.PhoneNumber) {
      if (group.root.get('MobileNumber'))
        val = group.root.get('MobileNumber').value;

      if (val == null || val.toString().trim() == '' || val.toString().length>20)
        return { required: true }
    }
 
    return null;
  }

  ngOnInit() {
    this.employeeIdentifierTypes = [{ label: 'Phone number', value: EmployeeIdentifierTypes.PhoneNumber }, { label: 'Employee ID', value: EmployeeIdentifierTypes.EmployeeID }];
    this.dateTime.setDate(this.dateTime.getDate());
    this.yearRange = (new Date().getFullYear() - 100) + ':' + (new Date().getFullYear());
    this.prepereForm();
    this.phoneValidate();
  }

  actionConfirmed() {
    this.router.navigate(['login']);
  }

  phoneValidate() {
    const phoneControl: AbstractControl = this.SignupForm.controls['MobileNumber'];

    phoneControl.valueChanges.subscribe(data => {

      let preInputValue: string = this.SignupForm.value.MobileNumber;
      let lastChar: string = preInputValue.substr(preInputValue.length - 1);

      var newVal = data.replace(/\D/g, '');


      if (data.length < preInputValue.length) {
        if (lastChar == ')') {
          newVal = newVal.substr(0, newVal.length - 1);
        }
        if (newVal.length == 0) {
          newVal = '';
        }
        else if (newVal.length <= 3) {
          newVal = newVal.replace(/^(\d{0,3})/, '($1');
        } else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1)-$2');
        } else {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1)-$2-$3');
        }
      } else {
        if (newVal.length == 0) {
          newVal = '';
        }
        else if (newVal.length <= 3) {
          newVal = newVal.replace(/^(\d{0,3})/, '($1)');
        } else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1)-$2');
        } else {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1)-$2-$3');
        }
      }
      this.SignupForm.controls['MobileNumber'].setValue(newVal, { emitEvent: false });
    });
  }

  ngOnDestroy() {
    this.SignupForm.reset();
  }

}
