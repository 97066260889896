import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../shared/config/services/config.service';
import { UserPreferencesService } from '../../shared/preferences/user-preferences.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ShiftsItem } from '../models/shifts.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ShiftsService {
  public static RESOURCE_SERVICE = 'ResourceService';
  private customerShiftsSubject: BehaviorSubject<any> = new BehaviorSubject(
    null
  );

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private userPreferencesService: UserPreferencesService
  ) {}

  getShiftsData(businessDate: string): Observable<ShiftsItem[]> {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${
      ShiftsService.RESOURCE_SERVICE
    }/api/CustomerCheckOuts?businessDate=${businessDate}&customerCode=${customerCode}`;
    return this.http
      .get(address)
      .pipe(map(jsonData => ShiftsItem.fromJson(jsonData)));
  }
  getCustomerShiftsFromApi(businessDate: string) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}${
      ShiftsService.RESOURCE_SERVICE
    }/api/CustomerShifts?businessDate=${moment(businessDate).format(
      'YYYY-MM-DD'
    )}&customerCode=${customerCode}`;
    return this.http
      .get(address)
      .pipe(
        shareReplay(1),
        tap(data => this.setCustomerShiftSubject(data))
      )
      .subscribe();
  }
  mergeAndSortApiDataWithShifts(dataFromApi) {
    const customerShifts = this.customerShifts.getValue()
      ? [...this.customerShifts.getValue()]
      : [];

    // putting data array in shift
    const apiDataWithShifts = customerShifts.map(shift => {
      return {
        ...shift,
        data: []
      };
    });

    let apiDataArray = [];
    apiDataArray = [...dataFromApi];

    // making missingTimeCardsData row
    const noShiftData = apiDataArray.reduce((all, temp) => {
      if (temp.trancol_shiftname === null) {
        all.push(temp);
        dataFromApi = dataFromApi.filter(element => element !== temp);
      }
      return all;
    }, []);

    // Adding api data in their shift row
    dataFromApi.map(temp => {
      apiDataWithShifts.map(shift => {
        if (temp.trancol_shiftname === shift.Name) {
          shift.data.push(temp);
        }
        if (temp.brkdowncol_shiftname === shift.Name) {
          shift.data.push(temp);
        }
        if (temp.teamcol_shiftname === shift.Name) {
          shift.data.push(temp);
        }
        if (temp.commcol_shiftname === shift.Name) {
          shift.data.push(temp);
        }
      });
    });

    // creating missingTimeCardsData row and adding it in apiDataWithShifts
    const missingTimeCard = apiDataWithShifts.findIndex(
      shift => shift.Name === 'TRANSACTIONS WITHOUT VALID TIME CARD'
    );

    if (noShiftData && noShiftData.length > 0) {
      if (missingTimeCard !== -1) {
        apiDataWithShifts[missingTimeCard].data = noShiftData;
      } else {
        apiDataWithShifts.push({
          data: noShiftData,
          Name: 'TRANSACTIONS WITHOUT VALID TIME CARD',
          number: '',
          startTime: '',
          endTIme: ''
        });
      }
    }
    return apiDataWithShifts;
  }
  get customerShiftsObservable() {
    return this.customerShiftsSubject.asObservable();
  }
  get customerShifts() {
    return this.customerShiftsSubject;
  }
  setCustomerShiftSubject(data) {
    this.customerShiftsSubject.next(data);
  }
}
