import * as moment from 'moment';
import * as _ from 'lodash';

const formatTime = time => moment.utc(time * 1000).format('mm:ss');

const cutString = (str, cutPlace) => {
  if (str !== null) {
    const string = str.toString();
    return string.slice(0, cutPlace);
  } else {
    return '';
  }
};

export class CheckOutsItem {
  constructor(
    private trancol_PoolDefined: any,
    private trancol_additionaltips: any,
    private trancol_cashsales: any,
    private trancol_cashtips: any,
    private trancol_ccsales: any,
    private trancol_cctipfee: any,
    private trancol_cctips: any,
    public trancol_clockedin: any,
    public trancol_clockedout: any,
    private trancol_commissionsales: any,
    private trancol_commissionsales_2: any,
    private trancol_commissionsales_3: any,
    private trancol_dailytranspk: any,
    private trancol_declaredcash: any,
    private trancol_emplid: Number,
    private trancol_employeepk: any,
    private trancol_foodsales: any,
    private trancol_indirecttip: any,
    private trancol_job: any,
    private trancol_jobgeneratesales: any,
    private trancol_jobid: any,
    private trancol_jobpk: any,
    private trancol_liquorsales: any,
    private trancol_name: any,
    private trancol_netsales: any,
    private trancol_overtime: any,
    private trancol_salescat4: any,
    private trancol_salescat5: any,
    private trancol_salescat6: any,
    private trancol_salescat7: any,
    private trancol_servicecharge: any,
    private trancol_servicechargecash: any,
    private trancol_servicechargecc: any,
    private trancol_shiftname: any,
    private trancol_shiftnumber: any,
    private trancol_shiftpk: any,
    private trancol_totalbreaktime: any,
    private trancol_totaltime: any,
    private trancol_winesales: any
  ) {
    this.trancol_emplid = Number(this.trancol_emplid);
    this.trancol_winesales = Number(this.trancol_winesales);
    this.trancol_foodsales = Number(this.trancol_foodsales);
    this.trancol_liquorsales = Number(this.trancol_liquorsales);
    this.trancol_netsales = Number(this.trancol_netsales);
    this.trancol_additionaltips = Number(this.trancol_additionaltips);
    this.trancol_cctipfee = Number(this.trancol_cctipfee);
    this.trancol_cctips = Number(this.trancol_cctips);
    this.trancol_declaredcash = Number(this.trancol_declaredcash);
    this.trancol_servicecharge = Number(this.trancol_servicecharge);
    this.trancol_servicechargecash = Number(this.trancol_servicechargecash);
    this.trancol_servicechargecc = Number(this.trancol_servicechargecc);
    this.trancol_cashsales = Number(this.trancol_cashsales);
    this.trancol_cashtips = Number(this.trancol_cashtips);
    this.trancol_clockedin = cutString(this.trancol_clockedin, 5);
    this.trancol_clockedout = cutString(this.trancol_clockedout, 5);
    this.trancol_totaltime = formatTime(this.trancol_totaltime);
    this.trancol_commissionsales = Number(
      this.trancol_commissionsales.toFixed(2)
    );
    this.trancol_commissionsales_2 = Number(
      this.trancol_commissionsales_2.toFixed(2)
    );
    this.trancol_commissionsales_3 = Number(
      this.trancol_commissionsales_3.toFixed(2)
    );
  }

  static fromJson(data: any): CheckOutsItem[] {
    const checkOutsArray: CheckOutsItem[] = [];
    data.forEach((item: CheckOutsItem) => {
      checkOutsArray.push(
        new CheckOutsItem(
          item.trancol_PoolDefined,
          item.trancol_additionaltips,
          item.trancol_cashsales,
          item.trancol_cashtips,
          item.trancol_ccsales,
          item.trancol_cctipfee,
          item.trancol_cctips,
          item.trancol_clockedin,
          item.trancol_clockedout,
          item.trancol_commissionsales,
          item.trancol_commissionsales_2,
          item.trancol_commissionsales_3,
          item.trancol_dailytranspk,
          item.trancol_declaredcash,
          item.trancol_emplid,
          item.trancol_employeepk,
          item.trancol_foodsales,
          item.trancol_indirecttip,
          item.trancol_job,
          item.trancol_jobgeneratesales,
          item.trancol_jobid,
          item.trancol_jobpk,
          item.trancol_liquorsales,
          item.trancol_name,
          item.trancol_netsales,
          item.trancol_overtime,
          item.trancol_salescat4,
          item.trancol_salescat5,
          item.trancol_salescat6,
          item.trancol_salescat7,
          item.trancol_servicecharge,
          item.trancol_servicechargecash,
          item.trancol_servicechargecc,
          item.trancol_shiftname,
          item.trancol_shiftnumber,
          item.trancol_shiftpk,
          item.trancol_totalbreaktime,
          item.trancol_totaltime,
          item.trancol_winesales
        )
      );
    });
    checkOutsArray.map(item => {
      return _.mapKeys(item, function(value, key) {
        if (key === 'trancol_commissionsales') {
          key = 'trancol_commission_sales';
        }
        if (key === 'trancol_commissionsales_2') {
          key = 'trancol_commission_sales_2';
        }
        if (key === 'trancol_commissionsales_3') {
          key = 'trancol_commission_sales_3';
        }
        item[key] = value;
        return item;
      });
    }); 
    return checkOutsArray;
  }
}
