import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
  OnChanges
} from "@angular/core";
import { ExportExcelService } from "../grid/services/export-excel.service";
import { RowsPerPageItem } from "../../reporting/models/rows-per-page.model";
import { PrintService } from "../print.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-grid-expanded",
  templateUrl: "./grid-expanded.component.html",
  styleUrls: ["./grid-expanded.component.scss"]
})
export class GridExpandedComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild("gridTable") gridTable: ElementRef;

  @Input() data: {}[];
  @Input() columns: {}[];
  @Input() showDeleteButton = false;
  @Input() showEditButton = false;
  @Input() showExpandButton = false;
  @Input() showTeamUpButton = false;
  @Input() showExportToExcel = true;
  @Input() id;
  public pageInfoMessage;
  public filteredValue = [null];
  public printTable;
  public pagination: Boolean = true;
  public selectedColumns: any[];
  public totalEntities: number;
  public showDataFrom: number;
  public showDataTo: number;
  rowsPerPageOptions: RowsPerPageItem[];
  public tableRows = { name: "7", code: "7" };
  public currentHeightForMultiSelect = {};
  public scrollHeightForMultiSelect = { height: "200px" };
  public printDocument = false;
  constructor(
    private excelService: ExportExcelService,
    private detector: ChangeDetectorRef,
    private printService: PrintService,
    private router: Router
  ) {}

  getRowsPerPageOptions() {
    const rowsPerPage = [
      { name: "5", code: "5" },
      { name: "7", code: "7" },
      { name: "10", code: "10" },
      { name: "15", code: "15" },
      { name: "25", code: "25" },
      { name: "50", code: "50" },
      { name: "100", code: "100" }
    ];
    return rowsPerPage;
  }
  onFilteredData({ filteredValue }) {
    this.showDataFrom = 1;
    this.filteredValue = [...filteredValue];
    this.changePageInfoMessage(filteredValue);
  }

  filteredValueExists(filteredValue){
    if(!filteredValue){
      return false;
    }
    //There is no value for this filter
    if(filteredValue.length === 0){
      return true;
    }
    //Init state
    if(filteredValue.length === 1 && filteredValue[0] == null){
      return false;
    }
    //Filter deleted
    if(filteredValue.length === this.totalEntities){
      return false;
    }
    return true;
  }

  changePageInfoMessage(filteredValue) {
    if (this.filteredValueExists(filteredValue)) {
      this.pageInfoMessage = `Showing ${
        filteredValue.length === 0 ? "0" : this.showDataFrom
      } to ${
        filteredValue.length < this.showDataTo
          ? filteredValue.length
          : this.showDataTo
      } of ${filteredValue.length} entries (filtered from ${
        this.totalEntities
      } total entries)`;
    } else {
      this.pageInfoMessage = `Showing ${this.showDataFrom} to ${this.showDataTo} of ${this.totalEntities} entities`;
    }
    if (filteredValue.length === this.totalEntities) {
      this.pageInfoMessage = `Showing ${this.showDataFrom} to ${this.showDataTo} of ${this.totalEntities} entities`;
    }
  }
  onDropDownChange(event) {
    this.tableRows = event.value;
    this.showDataFrom = 1;
    this.showDataTo = Number(this.tableRows.name);
    if (this.showDataTo > this.totalEntities) {
      this.showDataTo = this.totalEntities;
    }
    this.changePageInfoMessage(this.filteredValue);
    setTimeout(() => {
      this.setHeightOnInitAndChange();
    }, 500);
  }

  ngOnChanges() {}

  ngOnInit() {
    this.rowsPerPageOptions = this.getRowsPerPageOptions();
    this.initColumns();
    this.filteredValue = [null];
    this.totalEntities = this.data.length;
    this.showDataFrom = 1;
    this.showDataTo = Number(this.tableRows.name);
    if (this.showDataTo > this.totalEntities) {
      this.showDataTo = this.totalEntities;
    }
    this.pageInfoMessage = `Showing ${this.showDataFrom} to ${this.showDataTo} of ${this.totalEntities} entities`;
  }

  initColumns() {
    const storageId = this.id + "_selected_columns";
    let localStorageColumns = JSON.parse(localStorage.getItem(storageId));
    if (!localStorageColumns) {
      localStorageColumns = [];
    }
    this.columns.map(original => {
      const storageColumn = localStorageColumns.find(
        storageValue => original["field"] === storageValue["field"]
      );
      if (!storageColumn) {
        localStorageColumns.push({ field: original["field"], visible: true });
      }
    });
    this.selectedColumns = this.columns.filter(original => {
      const column = localStorageColumns.find(
        storageValue => original["field"] === storageValue["field"]
      );
      console.log("selectedColumns", this.selectedColumns);
      return column && column.visible;
    });
    //Reset if all columns are not visible, (error recovery try)
    if (this.selectedColumns.length === 0 && this.columns.length !== 0) {
      localStorageColumns = [];
      this.columns.map(original => {
        const storageColumn = localStorageColumns.find(
          storageValue => original["field"] === storageValue["field"]
        );
        if (!storageColumn) {
          localStorageColumns.push({ field: original["field"], visible: true });
        }
      });
      this.selectedColumns = this.columns;
      console.log("selectedColumns", this.selectedColumns);
    }
    localStorage.setItem(storageId, JSON.stringify(localStorageColumns));
  }

  onColumnsChange(event) {
    const storageId = this.id + "_selected_columns";
    let localStorageColumns = JSON.parse(localStorage.getItem(storageId));
    localStorageColumns = localStorageColumns.map(storageValue => {
      const existInColumns =
        this.columns.find(
          original => original["field"] === storageValue["field"]
        ) !== undefined;
      const existInSelColumns =
        this.selectedColumns.find(
          original => original["field"] === storageValue["field"]
        ) !== undefined;
      if (existInColumns) {
        return {
          field: storageValue["field"],
          visible: existInSelColumns
        };
      } else {
        return storageValue;
      }
    });
    localStorage.setItem(storageId, JSON.stringify(localStorageColumns));
    this.selectedColumns = this.columns.filter(original => {
      const column = localStorageColumns.find(
        storageValue => original["field"] === storageValue["field"]
      );
      return column && column.visible;
    });
    setTimeout(() => {
      this.setHeightOnInitAndChange();
    }, 100);
  }

  print(dt) {
    this.printService.setPrintContent(dt);
    window.open("./print", '_blank');
  }
  exitPrint() {
    this.printDocument = false;
  }
  saveToPdf() {
    this.excelService.saveToPdf(this.id, "myPdf");
  }
  exportToExcel() {
    const dataToExport = [
      ...this.excelService.getDataForExport(this.data, this.columns)
    ];
    this.excelService.exportAsExcelFile(dataToExport, "export from grid");
  }
  paginate(event) {
    const { first } = event;
    this.showDataFrom = first + 1;
    this.showDataTo = this.showDataFrom - 1 + Number(this.tableRows.name);
    if (this.showDataTo > this.totalEntities) {
      this.showDataTo = this.totalEntities;
    }
    if (this.filteredValue.length > 0 && this.filteredValue[0] != null) {
      this.changePageInfoMessage(this.filteredValue);
    } else {
      this.pageInfoMessage = `Showing ${this.showDataFrom} to ${this.showDataTo} of ${this.totalEntities} entities`;
    }
  }

  setHeightForMultiSelect(height) {
    return {
      //minHeight: `${height - 40}px`,
      maxHeight: `${height - 60}px`,
      top: "0 !important"
    };
  }

  setScrollHeight(height) {
    return `${height - 80}px`;
  }

  setHeightOnInitAndChange() {
    let height = (<HTMLElement>(
      this.gridTable.nativeElement
    )).getBoundingClientRect().height;
    if (height > 800) {
      height = 800;
    }
    this.currentHeightForMultiSelect = this.setHeightForMultiSelect(height);
    this.scrollHeightForMultiSelect["height"] = `${height - 109}px`;
    this.detector.detectChanges();
  }

  ngAfterViewInit() {
    this.setHeightOnInitAndChange();
  }
  toFixedValue(number, dec) {
    if(number !== undefined && number !== null){
      return number.toFixed(dec);
    } else {
      return number;
    }
  }
}
