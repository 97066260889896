import {Injectable, isDevMode} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class LogInterceptorService implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isDevMode()) {
      // console.info(req);
    }

    return next.handle(req).pipe(
      catchError((err) => {
        if (isDevMode()) {
          console.error(req);
        }
        return throwError(err);
      })
    );
  }
}
