import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorService } from '../error/services/error.service';
import * as HttpStatus from 'http-status-codes';
import { AuthService } from '../../auth/services/auth.service';
import { Location } from '@angular/common';
@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  public messages = [];
  constructor(
    private errorService: ErrorService,
    private authService: AuthService,
    private location: Location
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        () => {},
        errorResponse => {
          if (errorResponse instanceof HttpErrorResponse) {
            if (errorResponse.status === HttpStatus.UNAUTHORIZED) {
              // this.errorService.addErrors(['Unauthorized, or Bad token']);
              if (
                typeof errorResponse.error === 'object' ||
                typeof errorResponse.error === 'function'
              ) {
                if (
                  errorResponse.error.Message === 'Invalid token.' ||
                  errorResponse.error.Message ===
                    'Request is missing authorization token.'
                ) {
                  localStorage.setItem('lastPageAdress', this.location.path());
                  this.authService.logout();
                  return;
                }
                if (
                  errorResponse.error.Message ===
                  'Authorization has been denied for this request.'
                ) {
                  return;
                } else {
                  this.errorService.messages.push(errorResponse.error.Message);
                  this.errorService.addErrors([...this.errorService.messages]);
                }
              }
              return;
            }

            if (errorResponse.status === 400 && errorResponse.error) {
              if (
                typeof errorResponse.error === 'object' ||
                typeof errorResponse.error === 'function'
              ) {
                this.errorService.messages.push(errorResponse.error.Message);
                this.errorService.addErrors([...this.errorService.messages]);
                return;
              }
              this.errorService.messages.push(errorResponse.error);
              this.errorService.addErrors([errorResponse.error]);
              return;
            }

            if (
              errorResponse.status === HttpStatus.NOT_FOUND &&
              errorResponse.error
            ) {
              this.errorService.addErrors(
                Array.isArray(errorResponse.error)
                  ? errorResponse.error
                  : [errorResponse.error]
              );
              return;
            }
            if (errorResponse.error === '' && errorResponse.status === 500) {
              this.errorService.messages.push(`Unexpected error occurred.`);
              this.errorService.addErrors([...this.errorService.messages]);
            }
            if (errorResponse.error !== '' && errorResponse.status === 500) {
              if (Array.isArray(errorResponse.error)) {
                this.errorService.messages.push(errorResponse.error);
                this.errorService.addErrors([...this.errorService.messages]);
                return;
              }
              if (
                typeof errorResponse.error === 'object' ||
                typeof errorResponse.error === 'function'
              ) {
                this.errorService.messages.push(errorResponse.error.Message);
                this.errorService.addErrors([...this.errorService.messages]);
                return;
              }
              this.errorService.messages.push(errorResponse.error);
              this.errorService.addErrors([...this.errorService.messages]);
              return;
            }
            if (errorResponse.error !== '' && errorResponse.status === 501) {
              if (Array.isArray(errorResponse.error)) {
                this.errorService.messages.push(errorResponse.error);
                this.errorService.addErrors([...this.errorService.messages]);
                return;
              }
              if (
                typeof errorResponse.error === 'object' ||
                typeof errorResponse.error === 'function'
              ) {
                this.errorService.messages.push(errorResponse.error.Message);
                this.errorService.addErrors([...this.errorService.messages]);
                return;
              }
              this.errorService.messages.push(errorResponse.error);
              this.errorService.addErrors([...this.errorService.messages]);
              return;
            }
            if (errorResponse.status === 403) {
              this.errorService.messages.push(errorResponse.error);
              this.errorService.addErrors([...this.errorService.messages]);
            }
          }
          return throwError(errorResponse);
        }
      )
    );
  }
}
