import { Component, OnInit, OnDestroy } from '@angular/core';
import { PrintService } from 'src/app/modules/shared/print.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-print-page',
  templateUrl: './print-page.component.html'
})
export class PrintPageComponent implements OnInit, OnDestroy {
  public printContent;
  private $destroy = new Subject();
  constructor(private printService: PrintService) {}

  ngOnInit() {
    this.printService
      .getPrintContent()
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: printContent => {
          this.printContent = printContent;
        }
      });
  }
  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
