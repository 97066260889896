import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  OnInit
} from '@angular/core';

@Directive({
  selector: '[inputAddon]'
})
export class InputAddonDirective implements OnInit {
  constructor(private elementRef: ElementRef, private render: Renderer2) {}
  @Input() fill = false;
  @Input() icon: string;

  ngOnInit() {
    const span = this.render.createElement('span');
    const div = this.render.createElement('div');
    const icon = this.render.createElement('i');
    const iconText = this.render.createText(this.icon);
    this.render.appendChild(icon, iconText);
    this.render.appendChild(span, icon);
    this.render.appendChild(div, span);
    const parent = this.elementRef.nativeElement.parentNode;
    const refChild = this.elementRef.nativeElement;
    this.render.insertBefore(parent, div, refChild);
    this.render.addClass(
      this.elementRef.nativeElement,
      'form-input-field-addon'
    );

    this.render.addClass(span, 'form-input-icon-addon');
    this.render.addClass(div, 'form-input-wrapper-addon');
    this.render.addClass(icon, 'material-icons');

    this.render.parentNode(div);
    this.render.appendChild(div, this.elementRef.nativeElement);
  }
}
