export class MenuPageItem{
    ID: number;
    Code: string;
    Title: string;
    Description: string;

    constructor(item) {
        this.ID = item.ID;
        this.Code = item.Code;
        this.Title = item.Title;
        this.Description = item.Description;
    }

    static fromJson(data: any): MenuPageItem[] {
        const pageArray: MenuPageItem[] = [];
        data.forEach(item => {
            pageArray.push(new MenuPageItem(item));
        });
        return pageArray;
    }
}