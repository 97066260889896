import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-inner-spinner',
  template: `
    <div [ngStyle]="{'height': height,width:width}" class="donut"></div>
  `,
  styleUrls: ['./inner-spinner.component.scss']
})
export class InnerSpinnerComponent implements OnInit {
  @Input() height = '30px';
  @Input() width = '30px;';
  constructor() {}

  ngOnInit() {}
}
