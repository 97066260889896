import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-custom-pie',
    templateUrl: './custom-pie.component.html',
    styleUrls: ['./custom-pie.component.scss']
})

export class CustomPieComponent implements OnInit {

    @Input() percentage = 0;
    @Input() percentageBorder = 0;

    constructor() { }

    ngOnInit() {
    }

    getCorrectClassFromPercentage(proc) {
        return `pie-wrapper progress-${Math.floor(proc)} style-2`;
    }
}
