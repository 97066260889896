import { Component, Input, OnInit, } from '@angular/core';
import { DashboardService } from '../../navigation/services/dashboard.service';


@Component({
  selector: 'app-pop-up-confirmation',
  templateUrl:'./pop-up-confirmation.component.html',
  styleUrls:['./pop-up-confirmation.component.scss']
})

export class PopUpConfirmationComponent implements OnInit {
    
  @Input() showModal:Boolean;
  @Input() showCloseIcon:Boolean;
  @Input() onCloseModal: Function;

    constructor(private dashboardService:DashboardService) {
    }
  
    ngOnInit(): void {}     

    closeModal(){
      // this.dashboardService.showDeleteConfirmation.next(false);
      this.onCloseModal();
    }
}

