import { Component, OnInit, OnDestroy } from '@angular/core';
import { ErrorService } from '../error/services/error.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
  public errorExist;
  private $destroy = new Subject();

  constructor(private errorService: ErrorService) {}

  ngOnInit() {
    this.errorService
      .getErrors()
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: error => {
          if (error != null) {
            error.length > 0
              ? (this.errorExist = true)
              : (this.errorExist = false);

          }
        }
      });
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
