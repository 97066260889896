export class AnalyticsItem {
  code: string;
  per: string;
  total: string;

  constructor(code: string, per: string, total: number) {
    const cleanCode = code.replace('total_','').replace(/_/g, " ");
    this.code = cleanCode;
    this.per = per;
    this.total = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');;
  }

  static fromJson(data: any): AnalyticsItem[] {
    const analyticsArray: AnalyticsItem[] = [];
    data.DailyTotalsAndStats
    .forEach(item => {
      analyticsArray.push(new AnalyticsItem(item.Code, item.Per, item.Total));
    });
    return analyticsArray;
  }
}
