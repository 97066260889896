import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Customer } from '../../auth/model/customer.model';
import { EmployeeMappingStore } from '../../control-panel/components/employee-mapping/store/employee-mapping.store';
import { JobMappingStore } from '../../control-panel/components/job-mapping/store/job-mappings.store';
import { TransactionsStore } from '../../reporting/components/transactions/store/transactions.store';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {
  private keyNav = 'navigation_bookmarks';
  private keyCustomer = 'customers';
  private keyPage = 'page';
  private keyMenu = 'menu';
  private userCode = 'UserCode';
  private authContext = 'authContext';
  private pageObject: BehaviorSubject<any> = new BehaviorSubject({});
  private menuObject: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(private employeeMappingStore: EmployeeMappingStore,
    private jobMappingStore: JobMappingStore,
    private transactionsStore: TransactionsStore) {}

  getNavBookmarks(): Observable<string[]> {
    return of(JSON.parse(localStorage.getItem(this.keyNav)));
  }

  setNavBookmarks(value) {
    localStorage.setItem(this.keyNav, JSON.stringify(value));
  }

  getCustomerLocations(): Observable<Customer[]> {
    return of(localStorage.getItem(this.keyCustomer)).pipe(
      map(json => {
        console.log(json);
        if (json && !json.includes(null)) {
          const array: Customer[] = [];
          JSON.parse(json).forEach(item => {
            array.push(new Customer(item));
          });
          return array;
        }
      })
    );
  }

  setCustomerLocations(value) {
    localStorage.setItem(this.keyCustomer, JSON.stringify(value));
  }

  getCustomerCode() {
    if (localStorage.getItem(this.keyCustomer) != null) {
      const customers = JSON.parse(localStorage.getItem(this.keyCustomer));
      return customers[0]['Code'];
    } else {
      return '';
    }
  }

  setPageStructure(value) {
    localStorage.setItem(this.keyPage, JSON.stringify(value));
    this.pageObject.next(value);
    this.employeeMappingStore.set('pageObject', value);
    this.jobMappingStore.set('pageObject', value);
    this.transactionsStore.set('pageObject',value);
  }

  getPageStructure() {
    if (localStorage.getItem(this.keyPage) != null) {
      const pageObject = JSON.parse(localStorage.getItem(this.keyPage));
      return pageObject;
    } else {
      return [];
    }
  }
  getPageStructureObject() {
    return this.pageObject.asObservable();
  }
  get pageStructureValue() {
    return this.pageObject.value;
  }
  loadPageAndMenuData() {
    this.menuObject.next(this.getMenuStructure());
    this.pageObject.next(this.getPageStructure());
    this.employeeMappingStore.set('pageObject', this.getPageStructure());
  }

  getManuStructureObject() {
    return this.menuObject.asObservable();
  }

  setMenuStructure(value) {
    localStorage.setItem(this.keyMenu, JSON.stringify(value));
    this.menuObject.next(value);
  }

  getMenuStructure() {
    if (localStorage.getItem(this.keyMenu) != null) {
      const menuObject = JSON.parse(localStorage.getItem(this.keyMenu));
      return menuObject;
    } else {
      return [];
    }
  }
}
