import { ColumnItem } from './column-item.model';

/*Code: null
​​​​​
Columns: Array(10) [ {…}, {…}, {…}, … ]
​​​​​
DataType: null
​​​​​
IsDisabled: false
​​​​​
IsVisible: true
​​​​​
Title: null*/

export class TabItem{
    Code: string;
    DataType: number;
    IsDisabled: boolean;
    IsVisible: boolean;
    Title: string;
    Columns: ColumnItem[];

    constructor(item) {
        this.Code = item.Code;
        this.DataType = item.DataType;
        this.IsDisabled = item.IsDisabled;
        this.IsVisible = item.IsVisible;
        this.Title = item.Title;
        this.Columns = ColumnItem.fromJson(item.Columns);
    }

    static fromJson(data: any): TabItem[] {
        const tabArray: TabItem[] = [];
        data.forEach(item => {
            tabArray.push(new TabItem(item));
        });
        return tabArray;
    }
}