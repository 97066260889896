import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from '../../navigation/services/dashboard.service';
import { trigger, transition, animate, style, state } from '@angular/animations'

@Component({
  selector: 'app-pop-up-form',
  templateUrl: './pop-up-form.component.html',
  styleUrls: ['./pop-up-form.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('300ms ease-in', style({transform: 'translateY(5%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ]),
  ]
})
export class PopUpFormComponent implements OnInit {

  @Input() showModal:Boolean;
  @Input() showCloseIcon:Boolean;
  @Input() onCloseModal: Function;
  @Input() showGratuityCloseIcon:boolean = false;
  @Input() classStyle: string = ""
  public localClass: string = "pop-up-modal__content"

  constructor(private dashboardService:DashboardService) { }

  ngOnInit(): void {
    if(this.classStyle)
      this.localClass = this.localClass + " " + this.classStyle;
  }     

  closeModal(){
    this.dashboardService.showDeleteConfirmation.next(false)
    this.onCloseModal();
  }

}
