import { Customer } from './customer.model';
import { CustomersDto } from './customers.dto';

export class Customers implements CustomersDto {
  UserCode: number;
  Customers: Customer[];

  constructor(json) {
    Object.assign(this, json);

    if (json.Customers && json.Customers.length) {
      this.Customers = [];
      json.Customers.forEach(item => {
        if (item.CustomerActive) {
          this.Customers.push(new Customer(item));
        }
      });
    }
  }
}
