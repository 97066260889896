import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../../auth/services/auth.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const context = this.authService.getContextFromLocalStorage();

    if (context) {
      const accessToken = context.Token;
      const modified = req.clone({
        setHeaders: {'GratSync-Token': accessToken}
      });

      return next.handle(modified);
    }

    return next.handle(req);
  }
}
