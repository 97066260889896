import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  public static LOCALSTORAGE_ID = 'printContent';
  private printContent: BehaviorSubject<any> = new BehaviorSubject({});
  constructor() {}

  setPrintContent(printData) {
    this.printContent.next(printData);
    localStorage.setItem(PrintService.LOCALSTORAGE_ID, JSON.stringify({columns:printData._columns,value:printData._value}))
    window.open("./print", '_blank');
  }
  getPrintContent() {
    return this.printContent.asObservable();
  }
}
