export class DatatableColumn {
  field: string;
  translationLabel: string;


  constructor(field: string, translationLabel: string) {
    this.field = field;
    this.translationLabel = translationLabel;
  }
  
}
