import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UserPreferencesService } from '../../preferences/user-preferences.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import * as moment from 'moment';
import { ConfigService } from '../../config/services/config.service';
import { find, first, shareReplay, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  private API_URL = `${this.configService.getBaseUrl()}/ResourceService/api/Customer?`;
  private selectedMonthFromNavigator = new BehaviorSubject(moment());
  private selectedDate = new BehaviorSubject(moment());
  private $selectedDateObservable = this.selectedDate.asObservable();

  private typeOfDay = new BehaviorSubject('');
  private $typeOfDayObeservable = this.typeOfDay.asObservable();
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private userPreferenceService: UserPreferencesService,
    private authService: AuthService
  ) {}

  getTypeOfDay(): Observable<any> {
    return this.typeOfDay.asObservable();
  }
  setTypeOfDay(data) {
    this.typeOfDay.next(data);
  }
  getSelectedMonthFromNavigator(): Observable<any> {
    return this.selectedMonthFromNavigator.asObservable();
  }

  setSelectedMonthFromNavigator(date, callback) {
    this.selectedMonthFromNavigator.next(date);
    callback();
  }

  getDataFromSelectedDate(year, month): Observable<any> {
    const token = this.authService.getContextFromLocalStorage().Token;
    const customerCode = this.userPreferenceService.getCustomerCode();
    const headers = new HttpHeaders({ 'GratSync-Token': token });
    const params = new HttpParams()
      .set('year', year)
      .set('month', month)
      .set('customerCode', customerCode);
    return this.http
      .get(this.API_URL, { params, headers })
      .pipe(shareReplay(1));
  }

  getSelectedDate(): Observable<any> {
    return this.$selectedDateObservable;
  }

  setSelectedDate(date) {
    this.selectedDate.next(date);
  }

  isSameDate(firstDate, secondDate) {
    const formatedFirstDate = moment(new Date(firstDate)).format('DD-MM-YYYY');
    const formatedSecondDate = moment(new Date(secondDate)).format(
      'DD-MM-YYYY'
    );
    if (formatedFirstDate === formatedSecondDate) {
      return true;
    }
    return false;
  }
  isDateAvailable(objDate) {
    const todayDate = moment().toDate();
    const currentDate = objDate.toDate();
    return moment(currentDate).isAfter(todayDate);
  }
}
