export class CustomerEmployee {
    Employee_ID: any;
    Name: any;
    constructor(
        Employee_ID,
        Name,
    ) {
        this.Employee_ID = Employee_ID,
            this.Name = Name
    }

    static fromJson(data: any): CustomerEmployee[] {
        const employee: CustomerEmployee[] = [];
        data.forEach(e => {
            employee.push(
                new CustomerEmployee(
                    e.Employee_ID,
                    e.Name,
                )
            );
        });
        return employee;
    }
}
