import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomerTimeTab } from '../models/customer-time-tab.model';
import { CustomerEmployee } from '../models/customer-employee.model';
import { CustomerJobs } from '../models/customer-jobs.model';
import { ConfigService } from 'src/app/modules/shared/config/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { DatatableColumn } from '../../shared/customizable-table/model/datatable-header-column.model';
import { UserPreferencesService } from '../../shared/preferences/user-preferences.service';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TimeCardsService {
  private timeCardArray: BehaviorSubject<any> = new BehaviorSubject([]);
  private timeCardArrayForSecondTable: BehaviorSubject<
    any
  > = new BehaviorSubject({});
  private timeCardDate: BehaviorSubject<any> = new BehaviorSubject(null);
  private customerEmployeeArray: BehaviorSubject<any> = new BehaviorSubject([]);
  private customerJobsArray: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private configService: ConfigService,
    private userPreferencesService: UserPreferencesService,
    private http: HttpClient,
    private translationService: TranslateService
  ) {}

  findCurrentTab(Tabs, nameOfTabs) {
    if (Tabs.length > 0) {
      return Tabs.findIndex(tab => tab.Code === nameOfTabs);
    } else {
      return -1;
    }
  }

  setCustomerTimesArray(data) {
    this.timeCardArray.next(data);
  }

  setTimeCardDate(date) {
    this.timeCardDate.next(date);
  }

  getCustomerTimeArray() {
    return this.timeCardArray.asObservable();
  }

  getCustomerTimeArrayForSecondTable() {
    return this.timeCardArrayForSecondTable.asObservable();
  }

  getCustomerEmployeeArray() {
    return this.customerEmployeeArray.asObservable();
  }

  getCustomerJobsArray() {
    return this.customerJobsArray.asObservable();
  }

  setCustomerTimeArrayForSecondTable(data) {
    this.timeCardArrayForSecondTable.next(data);
  }

  setCustomerEmployeeArray(data) {
    this.customerEmployeeArray.next(data);
  }

  setCustomerEmployeeJobsArray(data) {
    this.customerJobsArray.next(data);
  }

  getTimeCardDate() {
    return this.timeCardDate.asObservable();
  }

  getCustomerTimes(date, callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const businessDate = moment(date).format('YYYY-MM-DD');
    const params = new HttpParams()
      .set('customerCode', customerCode)
      .set('businessDate', businessDate);
    const address = `
    ${this.configService.getBaseUrl()}ResourceService/api/CustomerTime`;
    this.http
      .get<CustomerTimeTab>(address, { params })
      .pipe(
        map(jsonData => {
          return CustomerTimeTab.fromJson(jsonData);
        })
      )
      .subscribe(data => {
        this.setCustomerTimesArray(data);
        callback();
      });
  }

  getCustomerTimeForSecondTable(date, employeeId, callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const businessDate = moment(date).format('YYYY-MM-DD');
    const params = new HttpParams()
      .set('customerCode', customerCode)
      .set('businessDate', businessDate)
      .set('employeeId', employeeId);
    const address = `
    ${this.configService.getBaseUrl()}ResourceService/api/CustomerTime`;
    this.http
      .get<any>(address, { params })
      .subscribe(data => {
        this.setCustomerTimeArrayForSecondTable(data);
        callback();
      });
  }

  getCustomerEmployees(callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const params = new HttpParams().set('customerCode', customerCode);
    const address = `
    ${this.configService.getBaseUrl()}ResourceService/api/CustomerEmployees`;
    this.http
      .get<CustomerEmployee[]>(address, { params })
      .pipe(
        map(jsonData => {
          return CustomerEmployee.fromJson(jsonData);
        })
      )
      .subscribe(data => {
        this.setCustomerEmployeeArray(data);
        callback();
      });
  }

  getCustomerJobs(callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const params = new HttpParams().set('customerCode', customerCode);
    const address = `
    ${this.configService.getBaseUrl()}ResourceService/api/CustomerJobs`;
    this.http
      .get<CustomerJobs[]>(address, { params })
      .pipe(
        map(jsonData => {
          return CustomerJobs.fromJson(jsonData);
        })
      )
      .subscribe(data => {
        this.setCustomerEmployeeJobsArray(data);
        callback();
      });
  }

  deleteTimeCard(date, id, note, callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const businessDate = moment(date).format('YYYY-MM-DD');
    const address = `
    ${this.configService.getBaseUrl()}ResourceService/api/CustomerTime`;
    const params = new HttpParams()
      .set('customerCode', customerCode)
      .set('businessDate', businessDate)
      .set('id', id)
      .set('note', note);
    return this.http
      .delete(address, { params })
      .pipe(map(response => response))
      .subscribe(
        response => {
          callback(response);
        },
        e => {
          callback(e.error);
        }
      );
  }

  deleteTimeBreaks(date, id, note, callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const businessDate = moment(date).format('YYYY-MM-DD');
    const address = `
    ${this.configService.getBaseUrl()}ResourceService/api/CustomerTimeBreak`;
    const params = new HttpParams()
      .set('businessDate', businessDate)
      .set('id', id)
      .set('note', note)
      .set('customerCode', customerCode);
    return this.http
      .delete(address, { params })
      .pipe(map(response => response))
      .subscribe(
        response => {
          callback(response);
        },
        e => {
          callback(e.error);
        }
      );
  }

  saveCustomerTimeCards(body, callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `
        ${this.configService.getBaseUrl()}ResourceService/api/CustomerTime?customerCode=${customerCode}`;
    return this.http
      .post(address, body)
      .pipe(map(response => response))
      .subscribe(
        response => {
          callback(response);
        },
        e => {
          callback(e.error);
        }
      );
  }

  saveCustomerBreaks(body, callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `
        ${this.configService.getBaseUrl()}ResourceService/api/CustomerTimeBreak?customerCode=${customerCode}`;
    return this.http
      .post(address, body)
      .pipe(map(response => response))
      .subscribe(
        response => {
          callback(response);
        },
        e => {
          callback(e.error);
        }
      );
  }

  getShiftTableColumns() {
    return [
      new DatatableColumn('ShiftName', 'Shift'),
      new DatatableColumn('JobTitle', 'Position'),
      new DatatableColumn('ClockIn', 'Clock In'),
      new DatatableColumn('ClockOut', 'Clock Out')
    ];
  }

  getTimeCardsColumnsForSecondTable() {
    return [
      new DatatableColumn(
        'JobTitle',
        this.translationService.instant('APP.COLUMN.POSITION')
      ),
      new DatatableColumn(
        'ClockIn',
        this.translationService.instant('APP.COLUMN.CLOCK_IN')
      ),
      new DatatableColumn(
        'ClockOut',
        this.translationService.instant('APP.COLUMN.CLOCK_OUT')
      ),
      new DatatableColumn(
        'RegHours',
        this.translationService.instant('APP.COLUMN.REG_HOUR')
      ),
      new DatatableColumn(
        'OvtHours',
        this.translationService.instant('APP.COLUMN.OVT_HOURS')
      )
    ];
  }

  getBreaksTableColumns() {
    return [
      new DatatableColumn('IsPayed', 'Paid'),
      new DatatableColumn('StartTime', 'Start'),
      new DatatableColumn('EndTime', 'End')
    ];
  }
  formatDate(date) {
    const currentDate = new Date(date);
    return `${currentDate.getFullYear()}/${currentDate.getMonth() +
      1}/${currentDate.getDate()} ${currentDate.getHours()}:${
      currentDate.getMinutes() === 0
        ? currentDate.getMinutes() + '0'
        : currentDate.getMinutes()
    }`;
  }
}
