export class ColumnItem {
  Code: string;
  DataType: number;
  IsDisabled: boolean;
  IsVisible: boolean;
  IsVisibleOnEdit: boolean;
  Title: string;

  constructor(item) {
    this.Code = item.Code;
    this.DataType = item.DataType;
    this.IsDisabled = item.IsDisabled;
    this.IsVisibleOnEdit = item.IsVisibleOnEdit;
    this.IsVisible = item.IsVisible;
    this.Title = item.Title;
  }

  static fromJson(data: any): ColumnItem[] {
    const columnArray: ColumnItem[] = [];
    data.forEach(item => {
      columnArray.push(new ColumnItem(item));
    });
    return columnArray;
  }
}
