import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/modules/shared/config/services/config.service';
import { UserPreferencesService } from '../../shared/preferences/user-preferences.service';
import { CloseDate } from '../models/close-date.model';
import * as moment from 'moment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ErrorService } from '../../shared/error/services/error.service';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DailyStatusService {
  public messageForCloseDate;
  public messageForOpenEditDate;
  public messageForSyncDate;
  public syncInProgress = new BehaviorSubject(false);
  public $syncInProgressObservable = this.syncInProgress.asObservable();
  private requestInterval;
  private syncDateResponse;
  private selectedDate = new BehaviorSubject(null);
  private selectedDateBreakDown = new BehaviorSubject(null);
  private selectedDateCheckOut = new BehaviorSubject(null);
  private selectedDateTeamUps = new BehaviorSubject(null);
  private selectedDateTime = new BehaviorSubject(null);
  private $selectedDateObservable = this.selectedDate.asObservable();
  private $selectedDateBreakDownObservable = this.selectedDateBreakDown.asObservable();
  private $selectedDateCheckOutObservable = this.selectedDateCheckOut.asObservable();
  private $selectedDateTeamUpsObservable = this.selectedDateTeamUps.asObservable();
  private $selectedDateTimeObservable = this.selectedDateTime.asObservable();
  private processIdForCloseDate;
  public currentItem = new BehaviorSubject<any>('');
  public currentItemObs = this.currentItem.asObservable();

  constructor(
    private configService: ConfigService,
    private userPreferencesService: UserPreferencesService,
    private errorService: ErrorService,
    private http: HttpClient
  ) {}

  getSelectedDate(): Observable<any> {
    return this.$selectedDateObservable;
  }

  getSelectedDateBreakDown(): Observable<any> {
    return this.$selectedDateBreakDownObservable;
  }

  getSelectedDateCheckOut(): Observable<any> {
    return this.$selectedDateCheckOutObservable;
  }

  getSelectedDateTeamUps(): Observable<any> {
    return this.$selectedDateTeamUpsObservable;
  }

  getSelectedDateTime(): Observable<any> {
    return this.$selectedDateTimeObservable;
  }

  setSelectedDate(date) {
    this.selectedDate.next(date);
    this.selectedDateBreakDown.next(date);
    this.selectedDateCheckOut.next(date);
    this.selectedDateTeamUps.next(date);
    this.selectedDateTime.next(date);
  }

  getCurrentCustomer(): Observable<any> {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const params = new HttpParams().set('customerCode', customerCode);
    const address = `
    ${this.configService.getBaseUrl()}ResourceService/api/Customer`;
    return this.http.get<any>(address, { params }).pipe(shareReplay(1));
  }

  customerCloseDate(ProcessID, BusinessDate, callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const businessDate = moment(BusinessDate).format('YYYY-MM-DD');
    const address = `${this.configService.getBaseUrl()}ResourceService/api/CustomerCloseDate?customerCode=${customerCode}`;
    const body = { BusinessDate, ProcessID, customerCode };
    this.http.put(address, body).subscribe(
      response => console.log('response', response),
      e => console.log('response', callback(e)),
      () => {
        this.selectedDate.next(businessDate);
        callback();
      }
    );
  }
  isCustomerCanCloseDate(date, callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const businessDate = moment(date).format('YYYY-MM-DD');
    const params = new HttpParams()
      .set('customerCode', customerCode)
      .set('businessDate', businessDate);
    const address = `${this.configService.getBaseUrl()}ResourceService/api/CustomerCloseDate?`;
    this.http
      .get<CloseDate>(address, { params })
      .pipe()
      .subscribe(
        response => {
          this.messageForCloseDate = response.Message;
          this.processIdForCloseDate = response.ProcessID;
        },
        error => callback(error),
        () => {
          if (this.messageForCloseDate === null) {
            this.customerCloseDate(
              this.processIdForCloseDate,
              businessDate,
              callback
            );
          } else {
            callback(this.messageForCloseDate);
            this.errorService.addErrors([this.messageForCloseDate]);
          }
        }
      );
  }
  customerOpenDate(BusinessDate, callback) {
    const businessDate = moment(BusinessDate).format('YYYY-MM-DD');
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}ResourceService/api/CustomerOpenDate?customerCode=${customerCode}`;
    const body = { BusinessDate: businessDate, customerCode };
    this.http.put(address, body).subscribe(
      response => console.log('response', response),
      e => console.log('response', callback(e)),
      () => {
        this.selectedDate.next(businessDate);
        callback();
      }
    );
  }
  isCustomerCanOpenDate(BusinessDate, callback) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const businessDate = moment(BusinessDate).format('YYYY-MM-DD');
    const params = new HttpParams()
      .set('customerCode', customerCode)
      .set('businessDate', businessDate);
    const address = `${this.configService.getBaseUrl()}ResourceService/api/CustomerOpenDate?`;
    this.http.get(address, { params }).subscribe(
      response => {
        this.messageForOpenEditDate = response;
      },
      e => {
        callback(e);
      },
      () => {
        if (this.messageForOpenEditDate === null) {
          this.customerOpenDate(businessDate, callback);
        } else {
          callback(this.messageForOpenEditDate.Message);
          this.errorService.addErrors([this.messageForOpenEditDate.Message]);
        }
      }
    );
  }
  customerSyncDate(businessDate) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const params = new HttpParams()
      .set('customerCode', customerCode)
      .set('businessDate', businessDate);
    const address = `${this.configService.getBaseUrl()}ResourceService/api/CustomerSyncDate`;
    this.http.get(address, { params }).subscribe(
      response => {
        this.syncDateResponse = response;
      },
      e => {
        console.log('e', e);
      },
      () => {}
    );
  }
  isCustomerCanSyncDate(BusinessDate, callback) {
    const businessDate = moment(BusinessDate).format('YYYY-MM-DD');
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}ResourceService/api/CustomerSyncDate?customerCode=${customerCode}`;
    const intervalTime = 1000;
    const body = { BusinessDate: businessDate, customerCode };
    this.http.put(address, body).subscribe(
      response => {
        this.messageForSyncDate = response;
      },
      e => callback(e),
      () => {
        if (this.messageForSyncDate === 'SyncDate request received.') {
          this.syncInProgress.next(true);
          let intervalCounter = 0;
          this.requestInterval = setInterval(() => {
            intervalCounter++;
            this.customerSyncDate(businessDate);
            if (intervalCounter >= 300) {
              clearInterval(this.requestInterval);
              this.errorService.addErrors([
                'Sync failed, Something went wrong!'
              ]);
              this.syncInProgress.next(false);
              callback();
            }
            if (this.syncDateResponse) {
              clearInterval(this.requestInterval);
              this.syncInProgress.next(false);
              callback(this.syncDateResponse);
            }
          }, intervalTime);
        } else {
          this.errorService.addErrors(['Something went wrong']);
        }
      }
    );
  }
  setCurrentItem(tab) {
    this.currentItem.next(tab);
  }
  getCurrentItem() {
    return this.currentItem;
  }
}
