import { Component, OnInit, OnDestroy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { ErrorService } from '../services/error.service';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit, OnDestroy {
  errors: string[] = [];
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private errorService: ErrorService
  ) {}

  ngOnInit() {
    this.errors = [...this.config.data.errors];
  }

  ngOnDestroy() {
    this.errorService.addErrors(null);
    this.errorService.messages = [];
  }
}
