import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AuthModule} from './modules/auth/auth.module';
import {AppRoutingModule} from './app-routing.module';
import {AuthGuard} from './modules/auth/services/auth-guard.service';
// import {AuthSkipLogin} from './modules/auth/services/auth-skip-login.service'
import {AuthService} from './modules/auth/services/auth.service';
import {SharedModule} from './modules/shared/shared.module';
import {GlobalErrorHandlerService} from './modules/shared/global-error-handler.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {ForgotPasswordComponent} from './views/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './views/reset-password/reset-password.component';
import {PrintPageComponent} from './views/print/print-page.component';
// Import containers
import {LoginComponent} from './views/login/login.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfigService} from './modules/shared/config/services/config.service';
import {MenuModule} from 'primeng/menu';
import {SharedModule as PrimeNgSharedModule} from 'primeng/shared';

import {
  BreadcrumbModule,
  CalendarModule,
  CheckboxModule,
  DialogModule,
  DropdownModule,
  InputTextModule,
  MultiSelectModule,
  PanelMenuModule,
  ProgressSpinnerModule,
  RadioButtonModule
} from 'primeng/primeng';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {NavigationModule} from './modules/navigation/navigation.module';
import {TableModule} from 'primeng/table';
import {LOCATION_INITIALIZED} from '@angular/common';
import {RefreshTokenInterceptor} from './modules/shared/interceptors/token-interceptor.service';
import {LogInterceptorService} from './modules/shared/interceptors/log-interceptor.service';
import {ErrorInterceptorService} from './modules/shared/interceptors/error-interceptor.service';
import {ReCaptchaModule} from 'angular2-recaptcha';
import {TestComponent} from './views/test/test.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {TimeCardsService} from './modules/dashboard/services/time-cards.service';
import {PasswordStrengthBarModule} from 'ng2-password-strength-bar';
import { SignupComponent } from './views/signup/signup.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'en';
      translate.setDefaultLang('en');
      translate.use(langToSet).subscribe(() => {
        console.info(`Successfully initialized '${langToSet}' language.'`);
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    TestComponent,
    PrintPageComponent,
    SignupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    NavigationModule,
    SharedModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MenuModule,
    PrimeNgSharedModule,
    ProgressSpinnerModule,
    InputTextModule,
    PanelMenuModule,
    DragDropModule,
    DropdownModule,
    MultiSelectModule,
    TableModule,
    DialogModule,
    BreadcrumbModule,
    ConfirmDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReCaptchaModule,
    PasswordStrengthBarModule,
    RadioButtonModule,
    CalendarModule,
    CheckboxModule
  ],
  providers: [
    ConfigService,
    AuthGuard,
    AuthService,
    ConfirmationService,
    GlobalErrorHandlerService,
    TimeCardsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
