import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import * as moment from 'moment';
import {Subscription, Subject} from 'rxjs';
import {CalendarService} from './services/calendar.service';
import {UserPreferencesService} from '../preferences/user-preferences.service';
import {skip} from 'rxjs/operators';
import {DailyStatusService} from '../../dashboard/services/daily-status.service';
import { AnalyticsService } from '../analytics-service.service';
import { ShiftsService } from '../../dashboard/services/shifts.service';
import { CheckOutsService } from '../../dashboard/services/check-outs.service';



@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
  @Input() canChangeNavMonthLogic: any;
  @Input() isAvailableLogic: any;
  @Output() approveAndCloseDate: EventEmitter<any> = new EventEmitter();
  @Output() openEditDate: EventEmitter<any> = new EventEmitter();
  @Output() syncDate: EventEmitter<any> = new EventEmitter();
   @Output() displaySelectedData: EventEmitter<any> = new EventEmitter();
  public isInitialOpenDayAvailable = false;
  public formatedSelectedDateForButton;
  public showModal = false;
  public initialOpenDate: string;
  public selectedDateType = 'open';
  public dataIsLoading = false;
  public selectedDayColor: string;
  private $destroy = new Subject();
  private locale = 'en';
  private titleDate: any;
  private currentDate: any;
  private weekDaysHeaderArr: Array<string> = [];
  private gridArr: Array<any> = [];
  private datesFromSelectedMonth: Array<any> = [];
  private selectedDate;
  $getSelectedDayColorSubscription: Subscription;
  $getSelectedMonthFromNavigatorSubscription: Subscription;
  $getSelectedDateSubscription: Subscription;
  constructor(
    private calenderService: CalendarService,
    private dailyStatusService: DailyStatusService,
    private analyticsService: AnalyticsService,
    private shiftService: ShiftsService,
    private checkOutsService: CheckOutsService
  ) {
  }

  ngOnInit() {
    this.dataIsLoading = true;
    moment.locale(this.locale);
    this.$getSelectedMonthFromNavigatorSubscription = this.calenderService
      .getSelectedMonthFromNavigator()
      .pipe(skip(1))
      .subscribe(value => {
        this.currentDate = value;
        this.getInitialDate();
      });
    this.$getSelectedDateSubscription = this.calenderService
      .getSelectedDate()
      .pipe(skip(1))
      .subscribe(date => {
        this.selectedDate = date;
        //this.getInitialDate();
      });
    this.$getSelectedDayColorSubscription = this.dailyStatusService
      .getSelectedDate()
      .pipe(skip(1))
      .subscribe(value => {
        this.selectedDate = value;
        this.formatedSelectedDateForButton = moment(value).format('M/D/YYYY');
        this.isInitialOpenDayAvailable = true;
        //this.getInitialDate();
      });
    this.makeHeader();
    this.getInitialDate();
  }

  getInitialDate() {
    if (this.currentDate) {
      const year = this.currentDate.format('YYYY');
      const monthNumber = this.currentDate.format('M');
      this.calenderService
        .getDataFromSelectedDate(year, monthNumber)
        .subscribe(data => {
          this.datesFromSelectedMonth = [...data];
          const currentDay = data.find(value => {
            return (
              value.BusinessDate.indexOf(
                this.selectedDate.format('YYYY-MM-DD')
              ) !== -1
            );
          });
          if (currentDay) {
            this.selectedDayColor = this.dateColor(
              this.changeTypeOfDate(currentDay.Closed)
            );
          }

          this.makeGrid();
          this.dataIsLoading = false;
          this.titleDate = this.currentDate;

        });
    }
  }

  changeNavMonth(num: number) {
    this.dataIsLoading = true;
    this.calenderService.setSelectedMonthFromNavigator(
      this.currentDate.add(num, 'month'),
      () => {
        //this.getInitialDate();
        this.selectDay(this.selectedDate);
      }
    );
  }

  onShowModal() {
    this.showModal = true;
    this.dataIsLoading = true;
    this.getInitialDate();
  }

  onCloseModal() {
    this.showModal = false;
  }

  borderColor(color) {
    if (color !== undefined) {
      let borderColor;
      switch (color) {
        case '#0096f8':
          this.calenderService.setTypeOfDay('open');
          return (borderColor = color);
        case '#53ba3a':
          this.calenderService.setTypeOfDay('closed');
          return (borderColor = color);
        case 'nodata':
          this.calenderService.setTypeOfDay('nodata');
          return '#ff001a';
        case 'invalid':
          this.calenderService.setTypeOfDay('invalid');
          return '#fff';
      }
    }
  }

  dateColor(type: string) {

    switch (type) {
      case 'open':
        return '#0096f8';
      case 'closed':
        return '#53ba3a';
      case 'nodata':
        return '#ff001a';
      case 'invalid':
        return '#fff';
    }
  }

  changeTypeOfDate(closed) {
    if (closed) {
      return 'closed';
    }
    if (!closed) {
      return 'open';
    }
  }

  canChangeNavMonth(num: number) {
    if (this.canChangeNavMonthLogic) {
      const clonedDate = moment(this.currentDate);
      return this.canChangeNavMonthLogic(num, clonedDate);
    } else {
      return true;
    }
  }

  makeHeader() {
    const weekDaysArr: Array<number> = [0, 1, 2, 3, 4, 5, 6];
    weekDaysArr.forEach(day =>
      this.weekDaysHeaderArr.push(
        moment()
          .weekday(day)
          .format('ddd')
      )
    );
  }

  makeGrid() {
    this.gridArr = [];
    const firstDayDate = moment(this.currentDate).startOf('month');
    const initialEmptyCells = firstDayDate.weekday();
    const lastDayDate = moment(this.currentDate).endOf('month');
    const lastEmptyCells = 6 - lastDayDate.weekday();
    const daysInMonth = this.currentDate.daysInMonth();
    const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;
    const isDateArrayFromApiEmpty =
      this.datesFromSelectedMonth.length > 0 ? false : true;

    for (let i = 0; i < arrayLength; i++) {
      const dateObject: any = {};
      if (i < initialEmptyCells || i > initialEmptyCells + daysInMonth - 1) {
        dateObject.value = 0;
        dateObject.available = false;
        dateObject.date = this.dateFromNumber(i, this.currentDate);
        dateObject.type = 'nodata';
        dateObject.id = i;
        dateObject.selected = false;
        if (this.calenderService.isDateAvailable(dateObject.date)) {
          dateObject.type = 'invalid';
        }
      } else {
        dateObject.available = true;
        dateObject.value = i - initialEmptyCells + 1;
        dateObject.date = this.dateFromNumber(
          i - initialEmptyCells + 1,
          this.currentDate
        );
        dateObject.type = 'nodata';
        dateObject.id = i;
        dateObject.selected = this.calenderService.isSameDate(
          this.dateFromNumber(i - initialEmptyCells + 1, this.currentDate),
          this.selectedDate
        );
        if (this.calenderService.isDateAvailable(dateObject.date)) {
          dateObject.type = 'invalid';
        }
      }

      this.gridArr.push(dateObject);
    }
    if (!isDateArrayFromApiEmpty) {

      this.gridArr.map((gridDate, index) => {
        this.datesFromSelectedMonth.map(apiDate => {
          const isSameDate = this.calenderService.isSameDate(
            gridDate.date,
            apiDate.BusinessDate
          );
          const isDateAvailable = this.calenderService.isDateAvailable(
            gridDate.date
          );
          if (isSameDate) {
            this.gridArr[index] = {
              ...gridDate,
              type: this.changeTypeOfDate(apiDate.Closed)
            };
          }
          if (isDateAvailable) {
            this.gridArr[index] = {
              ...gridDate,
              type: 'invalid'
            };
          }
        });
      });
      const selectedDateIndex = this.gridArr.findIndex(date => date.selected);
      if (selectedDateIndex !== -1) {

        this.selectedDateType = this.gridArr[selectedDateIndex]['type'];
      }
    }
  }

  isAvailable(num: number): boolean {
    if (this.isAvailableLogic) {
      const dateToCheck = this.dateFromNumber(num, this.currentDate);
      return this.isAvailableLogic(dateToCheck);
    } else {
      return true;
    }
  }

  dateFromNumber(num: number, referenceDate: any): any {
    const returnDate = moment(referenceDate);
    return moment().year(returnDate.year()).month(returnDate.month()).date(num);
  }

  setActiveDateBorder(selectedDate) {
    const indexOfSelectedDate = this.gridArr.findIndex(
      gridDate => gridDate.selected === true
    );
    const indexOfNewSelectedDate = this.gridArr.findIndex(
      gridDate => gridDate.id === selectedDate.id
    );
    if (selectedDate.available) {
      this.gridArr[indexOfSelectedDate] = {
        ...this.gridArr[indexOfSelectedDate],
        available: true,
        selected: false
      };
      this.gridArr[indexOfNewSelectedDate] = {
        ...this.gridArr[indexOfNewSelectedDate],
        available: true,
        selected: true
      };
    }
  }

  selectDay(day: any) {
    if (day.available) {
      const {type} = day;
      this.selectedDateType = type;
      const date = this.dateFromNumber(day.value, this.currentDate);
      // this.calenderService.setSelectedDate(date);
      this.selectedDate = date;
      this.setActiveDateBorder(day);
    }
  }

  ondisplaySelectedData() {
    const selectedDate = this.selectedDate;
    this.showModal = false;
    this.selectedDayColor = this.dateColor(this.selectedDateType);
    this.formatedSelectedDateForButton = moment(selectedDate).format(
      'M/D/YYYY'
    );
    //this.onDisplaySelectedDate(selectedDate);
    localStorage.setItem('isShiftCallBack', "false");
    this.dailyStatusService.setSelectedDate(selectedDate);
    this.analyticsService.getAnalyticsReportDataFromApi(selectedDate, selectedDate);
    this.shiftService.getCustomerShiftsFromApi(selectedDate);
    this.checkOutsService.getShiftsDataRow(
      moment(selectedDate),
      () => {
        this.dailyStatusService.setSelectedDate(
          moment(selectedDate)
        );
      }
    );
    //this.shiftService.getShiftsData(selectedDate);
    //this.ondailyStatus();
    //this.displaySelectedData.emit();
    
  }
  ondailyStatus(){
    //this.dailyStatus.emit();
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
    this.$getSelectedMonthFromNavigatorSubscription.unsubscribe();
    this.$getSelectedDateSubscription.unsubscribe();
    this.$getSelectedDayColorSubscription.unsubscribe();
  }

  onApproveAndCloseDate() {
    this.showModal = false;
    this.approveAndCloseDate.emit(this.selectedDate);
  }

  onOpenEditDate() {
    this.showModal = false;
    this.openEditDate.emit(this.selectedDate);
  }

  onSyncDate() {
    this.showModal = false;
    this.syncDate.emit(this.selectedDate);
  }
}
