import { PageObjectDto } from './page-object.dto';
import { PageItem } from './page-item.model';

export class PageObject implements PageObjectDto {
  change_my_details: PageItem;
  cnt_TransactionChangeLog: PageItem;
  dash_dailyStatus: PageItem;
  distributionrules: PageItem;
  rep_LoadCardReport: PageItem;
  rep_TransEndDate: PageItem;
  rep_PayrollExport: PageItem;
  rep_Withhold: PageItem;
  rep_audit: PageItem;
  rep_payments: PageItem;
  rep_totals: PageItem;
  set_EmplProfile: PageItem;
  set_ScheduleTraining: PageItem;
  set_cardDetails: PageItem;
  set_documents: PageItem;
  set_frequentlyAskedQuestions: PageItem;
  set_jobmappings: PageItem;
  set_mappings: PageItem;
  set_regManager: PageItem;
  set_regempl: PageItem;
  set_withhold: PageItem;
  setup_confingWizard: PageItem;

  constructor(item) {
    this.change_my_details = item.change_my_details
      ? new PageItem(item.change_my_details)
      : new PageItem({});
    this.cnt_TransactionChangeLog = item.cnt_TransactionChangeLog
      ? new PageItem(item.cnt_TransactionChangeLog)
      : new PageItem({});
    this.dash_dailyStatus = item.dash_dailyStatus
      ? new PageItem(item.dash_dailyStatus)
      : new PageItem({});
    this.distributionrules = item.distributionrules
      ? new PageItem(item.distributionrules)
      : new PageItem({});
    this.rep_LoadCardReport = item.rep_LoadCardReport
      ? new PageItem(item.rep_LoadCardReport)
      : new PageItem({});
    this.rep_TransEndDate = item.rep_TransEndDate
      ? new PageItem(item.rep_TransEndDate)
      : new PageItem({});
    this.rep_PayrollExport = item.rep_PayrollExport
      ? new PageItem(item.rep_PayrollExport)
      : new PageItem({});
    this.rep_Withhold = item.rep_Withhold
      ? new PageItem(item.rep_Withhold)
      : new PageItem({});
    this.rep_audit = item.rep_audit
      ? new PageItem(item.rep_audit)
      : new PageItem({});
    this.rep_payments = item.rep_payments
      ? new PageItem(item.rep_payments)
      : new PageItem({});
    this.rep_totals = item.rep_totals
      ? new PageItem(item.rep_totals)
      : new PageItem({});
    this.set_EmplProfile = item.set_EmplProfile
      ? new PageItem(item.set_EmplProfile)
      : new PageItem({});
    this.set_ScheduleTraining = item.set_ScheduleTraining
      ? new PageItem(item.set_ScheduleTraining)
      : new PageItem({});
    this.set_cardDetails = item.set_cardDetails
      ? new PageItem(item.set_cardDetails)
      : new PageItem({});
    this.set_documents = item.set_documents
      ? new PageItem(item.set_documents)
      : new PageItem({});
    this.set_frequentlyAskedQuestions = item.set_frequentlyAskedQuestions
      ? new PageItem(item.set_frequentlyAskedQuestions)
      : new PageItem({});
    this.set_jobmappings = item.set_jobmappings
      ? new PageItem(item.set_jobmappings)
      : new PageItem({});
    this.set_mappings = item.set_mappings
      ? new PageItem(item.set_mappings)
      : new PageItem({});
    this.set_regManager = item.set_regManager
      ? new PageItem(item.set_regManager)
      : new PageItem({});
    this.set_regempl = item.set_regempl
      ? new PageItem(item.set_regempl)
      : new PageItem({});
    this.set_withhold = item.set_withhold
      ? new PageItem(item.set_withhold)
      : new PageItem({});
    this.setup_confingWizard = item.setup_confingWizard
      ? new PageItem(item.setup_confingWizard)
      : new PageItem({});
  }

  static fromJson(data: any): PageObject {
    const page: PageObject = new PageObject(data.PageObjects);
    return page;
  }
}
