import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../../shared/config/services/config.service';
import { UserPreferencesService } from '../../shared/preferences/user-preferences.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { JobMappingStore } from '../components/job-mapping/store/job-mappings.store';
import { JobMappingItem } from '../../control-panel/models/job-mappings-mappings.model';
import { JobMappingWorkersForMappingItem } from '../../control-panel/models/job-mappings-jobsForMapping.model';
@Injectable({
  providedIn: 'root'
})
export class ServiceStatusMessageService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private userPreferencesService: UserPreferencesService,
    private jobMappingStore: JobMappingStore
  ) { }

  setMessage(data) {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}ResourceService/api/ServiceStatusMessage/SetMessage?customerCode=${customerCode}`;
    return this.http.post(address, data);
  }

  getAll() {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}ResourceService/api/ServiceStatusMessage/GetAll?customerCode=${customerCode}`;
    return this.http.get(address);
  }

  getActive() {
    const customerCode = this.userPreferencesService.getCustomerCode();
    const address = `${this.configService.getBaseUrl()}ResourceService/api/ServiceStatusMessage/GetActive?customerCode=${customerCode}`;
    return this.http.get(address);
  }

}
