import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, share} from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UserPreferencesService } from '../../shared/preferences/user-preferences.service';
import { AuthService } from '../../auth/services/auth.service';
import { TimeCard } from '../../dashboard/models/time-card.model';
import { ConfigService } from '../../shared/config/services/config.service';


@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    public _configService: ConfigService;
    public cardsTableData: BehaviorSubject<any> = new BehaviorSubject([]);
    public cardsTableDataObservable$ = this.cardsTableData.asObservable();
    public timeTableData: BehaviorSubject<any> = new BehaviorSubject([]);
    public timeTableDataObservable$ = this.timeTableData.asObservable();
    public timeCardsFromSelectedRow;
    public showDeleteConfirmation: BehaviorSubject<Boolean> = new BehaviorSubject(false);
    public showDeleteConfirmationObservable$ = this.showDeleteConfirmation.asObservable();
    public hardCodedToken = '5pzi+cYLZpv4XF/Wa74jJCvWhBXlubZhnzDMDz2n644=';
    public timeApiUrl = `${this.configService.getBaseUrl()}/ResourceService/api/CustomerTime?`;

    constructor(
        private authService: AuthService,
        private configService: ConfigService,
        private http: HttpClient,
        private userPreferenceService: UserPreferencesService) { 
            this._configService = configService;
        }

    getDataFromSelectedDate(selectedDate) {
        const token = this.authService.getContextFromLocalStorage().Token;
        const headers = new HttpHeaders({ 'GratSync-Token': this.hardCodedToken });
        const customerCode = this.userPreferenceService.getCustomerCode();
        const params = new HttpParams().set('businessDate', selectedDate);
        return this.http.get(`${this.timeApiUrl}`, { params, headers })
            .pipe(map(response => response), )
            .subscribe(data => { this.timeTableData.next(data) })
    }

    getTimeCardsFromSelectedRow(selectedDate, employeeId): Observable<TimeCard> {
        const headers = new HttpHeaders({ 'GratSync-Token': this.hardCodedToken });
        const params = new HttpParams().set('businessDate', selectedDate).set('employeeId', employeeId);
        return this.http.get<TimeCard>(`${this.timeApiUrl}`, { params, headers })
            .pipe(map(response => new TimeCard(response)), share());
    }

    deleteTimeCard(businessDate, id, encodedNote) {
        const headers = new HttpHeaders({ 'GratSync-Token': this.hardCodedToken });
        const params = new HttpParams().set('businessDate', businessDate).set('id', id).set('note', encodedNote);
        return this.http.delete(`${this.timeApiUrl}`, { params, headers })
            .pipe(map(response => response));
    }

    customerTimeApiUrl(){
        return this.timeApiUrl;
    }
}